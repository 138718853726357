import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import theme from '@jetshop/ui/utils/theme';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
import { ReactComponent as TrashSVG } from '../../svg/trash.svg';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  border-top: 1px solid ${theme('colors.lightgrey')};
  margin-top: 1rem;

  > :first-child {
    flex: 1 1 25%;
  }
  > :last-child {
    flex: 1 1 70%;
    padding: 0.5rem;
  }
`;

const ProductName = styled('div')`
  color: ${theme('colors.black')};
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
  padding-right: 30px;
  h2 {
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    text-transform: none;
    margin: 0;
    width: calc(100% - 0.5rem);
  }
  .attribute {
    display: block;
    font-size: 14px;
  }
  a {
    color: ${theme('colors.black')};
    transition: all ease 0.3s;
    text-decoration: none;
    :hover {
      color: ${theme('colors.primary')};
    }
  }
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ItemDetails = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: right;
  .new-price {
    color: ${({ theme }) => theme.colors.red};
  }
  .old-price {
    color: ${({ theme }) => theme.colors.mediumgrey};
    text-decoration: line-through;
  }
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  /* Plus/minus buttons */
  button {
    background: ${theme('colors.lightgrey')};
    color: ${theme('colors.black')};
    border-radius: 50%;
    font-size: 14px;
    height: 20px;
    line-height: 1;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &:disabled {
      opacity: 0.4;
    }
  }

  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }
`;

const RemoveItem = styled('div')`
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  button {
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: none;
    background: transparent;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 1.25em;
    width: 1.25em;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage,
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery,
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery,
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery,
  });

  const checker = (text) => {
    if (text?.toLowerCase().indexOf('storlek') > -1) {
      text = 'Storlek';
    } else if (text?.toLowerCase().indexOf('längd') > -1) {
      text = 'Längd';
    }
    return text;
  };

  const variantNamesValues = variantValues.map(
    (value, index) => `${checker(item.variantOptionNames[index])}: ${value}`
  );
  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image
            aspect="1:1"
            sizes="5rem"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
            quality={80}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <FlyoutTrigger id="cart-flyout">
            {(flyout) => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item.product.primaryRoute.path}>
                  <h2 data-testid="item-name">{item.product.name}</h2>
                  {isVariant && (
                    <ul
                      className={css`
                        margin-top: 0.5rem;
                      `}
                    >
                      {variantNamesValues.map(
                        (variantOptionNameValue, index) => (
                          <li className="attribute" key={index}>
                            {variantOptionNameValue}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                  {item.configurations.length > 0 && (
                    <ul
                      className={css`
                        margin-top: 0.5rem;
                      `}
                    >
                      {item.configurations.map(
                        ({ option: { name } }, index) => (
                          <li className="attribute" key={index}>
                            {name}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </Link>
              </ProductName>
            )}
          </FlyoutTrigger>
          <RemoveItem>
            <button
              onClick={() =>
                removeFromCart({ itemId: item.id, product: item.product })
              }
              data-testid="remove-from-cart"
            >
              <TrashSVG />
            </button>
          </RemoveItem>
        </ItemDetails>

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
