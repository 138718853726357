import React from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';

const Wrapper = styled('div')`
  padding: 0;
  :first-of-type {
    border-left: none;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
    margin-top: -8px;
    width: 100%;
  }

  li {
    color: #8f8f8f;
    flex: auto;
    line-height: 1.5;
    max-width: 10rem;
    padding: 0;
    a {
      opacity: 1;
      padding: 0;
      display: block;
      transition: all ease 0.3s;
      :hover,
      &.active {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
    &:last-of-type {
      a {
        margin-bottom: 1rem;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.8;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const Heading = styled('h2')`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  a {
    transition: all ease 0.3s;
    margin-bottom: 0.5rem;
  }
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading.name}
        </CategoryLink>
      </Heading>
      <ul>
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
