import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import styled from 'react-emotion';

const Push = styled(Image)`
  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    ${({ theme }) => theme.below.md} {
      padding: 15px;
    }
  }
`;

const PushImage = styled(Image)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px !important;
  padding-bottom: 0 !important;
  height: 120px !important;
  margin-right: 20px;
  ${({ theme }) => theme.below.md} {
    margin-right: 5px;
  }
`;

const PushContent = styled('div')`
  color: #000;
  margin-left: 20px;
  ${({ theme }) => theme.below.md} {
    margin-left: 5px;
  }
  h3 {
    text-align: center;
    color: #000;
    font-size: 25px;
    line-height: 1;
    ${({ theme }) => theme.below.md} {
      font-size: 1rem;
    }
  }
  p {
    font-size: 25px;
    ${({ theme }) => theme.below.md} {
      font-size: 1rem;
      text-align: center;
    }
  }
`;

const StartPagePush = ({ heading, text, url, imageSrc, background, color }) => (
  <Push fillAvailableSpace={true} src={background}>
    <a href={url}>
      <PushImage aspect="1:1" src={imageSrc} />
      <PushContent>
        <h3 style={{ color: color }}>{heading}</h3>
        <p style={{ color: color }}>{text}</p>
      </PushContent>
    </a>
  </Push>
);

export default StartPagePush;
