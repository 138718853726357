import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import React from 'react';
import styled from 'react-emotion';

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-bottom: 0.5rem;
`;

const StyledDropdownMenuButton = styled(DropdownMenuButton)`
  background: #fff !important;
  border-radius: 0 !important;
  border-color: #ddd !important;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 0.25rem;
  outline: none;
  text-align: left;
`;

const StyledDropdownMenuItems = styled(DropdownMenuItems)`
  border: 1px solid #ddd;
  border-top: 0 !important;
  margin-top: -1px;
`;

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  font-size: 12px;
  outline: none;
  transition: all ease 0.3s;
  :hover {
    background: #f3f3f3;
  }
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.primary}!important;
  }
  svg {
    use {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export function SelectVariant({ product }) {
  const { update } = useProductList();
  const initialVariant = product.variant;

  const {
    selectedVariant,
    selectValue,
    getSelectedValue,
    validateSelection
  } = useProductVariants(product, {
    initialVariant
  });

  React.useEffect(() => {
    // When a variant is selected, call the update function from useProductList to update the list
    function setVariant(variantArticleNumber) {
      update(product.articleNumber, {
        variantArticleNumber,
        variantToReplace: product.variant?.articleNumber,
        quantity: 1
      });
    }

    const currentArticleNumber = product.variant?.articleNumber;

    if (!selectedVariant) return;

    if (currentArticleNumber !== selectedVariant.articleNumber) {
      setVariant(selectedVariant.articleNumber);
    }
  }, [product.articleNumber, product.variant, selectedVariant, update]);

  return (
    <>
      {product.variants.options.map(option => {
        const selectedValue = getSelectedValue(option);
        var title = false;
        if (option?.name?.toLowerCase().indexOf('storlek') > -1) {
          title = 'Storlek';
        } else if (option?.name?.toLowerCase().indexOf('längd') > -1) {
          title = 'Längd';
        }
        return (
          <StyledDropdownMenu key={option.name}>
            <StyledDropdownMenuButton>
              {selectedValue
                ? (title ? title : option.name) + ' ' + selectedValue
                : t.rich('Select {option}', {
                    option: title ? title : option.name
                  })}
            </StyledDropdownMenuButton>
            <StyledDropdownMenuItems style={{ zIndex: 9999 }}>
              {option.values.map(value => {
                const validation = validateSelection(value, option);

                return (
                  <StyledDropdownMenuItem
                    key={value}
                    disabled={validation === 'invalid'}
                    closeOnSelect={true}
                    onSelect={() => {
                      selectValue(value, option);
                    }}
                    style={{ opacity: validation !== 'invalid' ? 1 : 0.5 }}
                  >
                    {value}
                    {selectedValue === value && <Check />}
                  </StyledDropdownMenuItem>
                );
              })}
            </StyledDropdownMenuItems>
          </StyledDropdownMenu>
        );
      })}
    </>
  );
}
