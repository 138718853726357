import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext, useEffect } from 'react';
import Helmet from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
// import CookieBar from './CookieBar/CookieBar';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { AddwishProvider } from '@jetshop/flight-addwish';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faStar,
  faShoppingBag,
  faMobileAndroidAlt,
  faSortAmountDown,
  faSortAlphaDown,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faArrowLeft,
  faTimes,
  faFilter,
} from '@fortawesome/pro-light-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import StartPage from './StartPage/StartPage';
import { LipscoreInit } from './Lipscore/LipscoreInit';

library.add(
  faCheck,
  faStar,
  fasStar,
  faShoppingBag,
  faMobileAndroidAlt,
  faSortAmountDown,
  faSortAlphaDown,
  faSortDown,
  faAngleRight,
  faAngleUp,
  faLongArrowRight,
  faAngleDown,
  faArrowLeft,
  faTimes,
  faFilter
);

const Store = loadable(() => import('./Store/Store'), {
  fallback: LoadingPage,
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: LoadingPage,
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: LoadingPage,
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: LoadingPage,
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: LoadingPage,
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: LoadingPage,
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: LoadingPage,
});

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: LoadingPage,
  }
);

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: LoadingPage,
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: LoadingPage,
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: LoadingPage,
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: LoadingPage,
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: LoadingPage,
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: LoadingPage,
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: LoadingPage,
});

const LoadableAcademyPage = loadable(() => import('./Academy/AcademyPage'), {
  fallback: LoadingPage,
});
const LoadableSingleAcademy = loadable(
  () => import('./Academy/SingleAcademy'),
  {
    fallback: LoadingPage,
  }
);

const LoadableProductSearch = loadable(
  () => import('./SearchPage/ProductSearchPage'),
  {
    fallback: LoadingPage,
  }
);

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <GenericError>
      <AddwishProvider>
        <ModalProvider>
          <FaviconSelector />
          <Container>
            <LoadingBar />
            <Helmet
              defaultTitle={selectedChannel.id === 1 ? 'SkiStore' : 'MTBStore'}
            />
            {/* <CookieBar /> */}
            <LipscoreInit />
            <ProductListProvider queries={productListQueries}>
              <Header />
              <Content>
                <PaginationProvider defaultProductsPerPage={40}>
                  <Switch>
                    <Route exact path="/" component={StartPage} />
                    <Route
                      path={routes.search.path}
                      component={LoadableSearchPage}
                    />
                    <Route
                      path={routes.signup.path}
                      component={LoadableSignUpPage}
                    />
                    <Route path={routes.login.path} component={LogInPage} />
                    <Route path={routes.stores.path} component={StoreLocator} />
                    <Route
                      path={`${routes.store.path}/:id`}
                      component={Store}
                    />
                    <Route path={routes.tree.path} component={NavTreePage} />
                    <Route
                      path={routes.myPages.path}
                      component={LoadableMyPages}
                    />
                    <Route
                      path="/kunskapsbank/:lvl2/:lvl3"
                      component={LoadableSingleAcademy}
                    />
                    <Route
                      path="/kunskapsbank"
                      component={LoadableAcademyPage}
                    />
                    <Route
                      path="/guides/:lvl2/:lvl3"
                      component={LoadableSingleAcademy}
                    />
                    <Route path="/guides" component={LoadableAcademyPage} />
                    <Route
                      path="/service/:lvl2/:lvl3"
                      component={LoadableSingleAcademy}
                    />
                    <Route path="/service" component={LoadableAcademyPage} />
                    <Route
                      exact
                      path={routes.forgotPassword.path}
                      component={ForgotPassword}
                    />
                    <Route
                      path="/service-1/:lvl2/:lvl3"
                      component={LoadableSingleAcademy}
                    />
                    <Route path="/service-1" component={LoadableAcademyPage} />
                    <Route
                      exact
                      path={routes.forgotPassword.path}
                      component={ForgotPassword}
                    />
                    <Route
                      path={`${routes.resetPassword.path}/:token`}
                      component={ResetPassword}
                    />
                    <Route
                      path="/preview"
                      render={(props) => (
                        <PreviewRoute
                          productPage={LoadableProductPage}
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          categoryPage={LoadableCategoryPage}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/product-search"
                      component={LoadableProductSearch}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </PaginationProvider>
              </Content>
              <Footer />
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
          </Container>
        </ModalProvider>
      </AddwishProvider>
    </GenericError>
  );
}

export default Shop;

const FaviconSelector = () => {
  const { selectedChannel } = useContext(ChannelContext);

  useEffect(() => {
    // Clear cache to ensure the favicon updates
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href += '?' + new Date().getTime();
    }
  }, [selectedChannel]);

  return (
    <Helmet>
      {selectedChannel.id === 1 ? (
        <>
          <meta property="og:image" content="/favicon-skistore.png" />
          <link
            rel="icon"
            type="image/png"
            href="/favicon-skistore.png"
            sizes="32x32"
          />
          <link rel="apple-touch-icon" href="/favicon-skistore.png" />
        </>
      ) : (
        <>
          <meta property="og:image" content="/favicon-mtbstore.png" />
          <link
            rel="icon"
            type="image/png"
            href="/favicon-mtbstore.png"
            sizes="32x32"
          />
          <link rel="apple-touch-icon" href="/favicon-mtbstore.png" />
        </>
      )}
    </Helmet>
  );
};
