import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import theme from '@jetshop/ui/utils/theme';
import React, { useContext } from 'react';
import { Query, useQuery } from 'react-apollo';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import ChannelSelector, {
  ChannelSelectorTarget
} from './ChannelSelector/ChannelSelector';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import TopNav from './TopNav';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import UspBar from './UspBar';
import { Favourites } from '../../ProductList/Favourites';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBar } from './SearchBar';
import { ReactComponent as MtbStoreLogo } from '../../../svg/MtbStoreLogo.svg';
import { ReactComponent as SkiStoreLogo } from '../../../svg/SkiStoreLogo.svg';
import { ReactComponent as MtbStoreWhite } from '../../../svg/mtbstore_white.svg';
import { ReactComponent as SkiStoreWhite } from '../../../svg/skistore_white.svg';
import { PhoneNumberFetch } from './getPhoneNumber.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import MaxWidth from '../MaxWidth';
import rafThrottle from '../../ui/Utils/rafThrottle';
import Helmet from 'react-helmet-async';

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 18px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  :hover {
    text-decoration: none;
  }
`;

const HeaderContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  z-index: 1;
  background: #d5d5d5;
  ${theme('below.md')} {
    width: 100%;
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  svg {
    color: #fff;
    height: 25px;
    width: 25px;
  }
  :focus,
  :active,
  :hover {
    outline: none;
    opacity: 0.8;
  }
  label {
    margin-top: 3px;
  }
`;

const MainHeader = styled('main')`
  align-items: flex-start;
  background: #000;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  padding: 0.5rem 20px 0.5rem 15px;
  transition: all, 0.2s ease;
  margin-top: 48px;

  position: fixed;
  position: sticky;
  top: 0;

  &.scrolled {
    ${theme('below.md')} {
      padding: 15px 20px;
    }
    ${theme('above.md')} {
      padding: 0rem 20px 0 15px;
      .logo {
        max-height: 55px;
        transform: scale(0.8);
        transition: all ease 0.5s;
        ${theme('below.md')} {
          transform: scale(1);
        }
      }
    }
    nav {
      margin-top: 15px;
      ul li a {
        //margin-bottom: 10px;
      }
    }
  }
  .logo {
    display: block;
    max-width: 120px;
    ${theme('below.md')} {
      max-width: 65px;
      margin: auto;
    }
  }
  width: 100%;
  z-index: 9;
  ${theme('below.md')} {
    background: ${theme('colors.black')};
    width: 100%;
    padding: 10px 20px;
    justify-content: space-between;
    margin-top: 32px;
  }
`;

const StyledLogo = styled('div')`
  margin-top: 1.1rem;
  width: 120px;
  transition: all, 0.2s ease;
  z-index: 1;
  &.scrolled {
    ${theme('above.md')} {
      margin-top: 0.3rem;
    }
  }
  ${theme('below.md')} {
    margin-top: 0;
    width: 65px;
    flex: 2;
    max-height: 100px;

    &.scrolled {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
    }
  }
`;

const SearchNav = styled('div')`
  padding-top: 1rem;
  padding-bottom: 0;
  z-index: 1;
  ${theme('below.md')} {
    width: 100%;
    padding: 1rem 0 0 0;
    background: ${theme('colors.black')};
    transition: all, 0.2s ease;

    &.scrolled {
      position: absolute;
      left: 70px;
      right: 100px;
      width: auto;
      padding: 0;
      bottom: 10px;
      z-index: 1;
    }
  }
`;

const Buttons = styled('div')`
  display: flex;
  font-size: 30px;
  margin-top: 0.6rem;
  z-index: 1;
  button {
    background: none;
    color: #fff;
  }
  ${theme('below.md')} {
    font-size: 22px;
    margin-top: 0;
    flex: 1;
    justify-content: flex-end;
  }
`;

const Phone = styled('div')`
  ${theme('below.lg')} {
    display: none;
  }
  span {
    margin-left: 0.25rem;
  }
  svg {
    height: 16px;
    width: 10px;
    margin: 0;
    margin-bottom: -2px;
    use {
      fill: #000;
    }
  }
`;

const TopNavLinks = styled('div')`
  display: flex;
  a {
    margin-left: 1.5rem;
    text-decoration: none;
    &:last-of-type {
      margin-right: 1.5rem;
    }
  }
`;

const ChangeStore = styled('div')`
  align-items: center;
  background: ${({ isSkiStore }) => (isSkiStore ? '#5B702E' : '#f8b133')};
  color: white;
  cursor: pointer;
  display: flex;
  height: 48px;
  transition: all ease 0.3s;
  :hover {
    background: ${({ isSkiStore }) => (isSkiStore ? '#4B601E' : '#d0942b')};
  }

  a {
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  ${theme('below.md')} {
    display: none;
  }
  svg {
    width: 100px;
    margin: 0;
    path,
    g,
    use {
      fill: white;
    }
  }
`;

const StyledTopNavRight = styled('div')`
  a {
    color: ${theme('colors.black')};
    &:hover,
    &.active {
      ${theme('colors.grey')} !important;
    }
  }
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const MaxedWidth = styled(MaxWidth)`
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 !important;
  max-width: 100%;
  &.logo-row {
    //z-index: 1;
  }
`;

const MaxedWidthCategories = styled(MaxedWidth)`
  justify-content: center;
  margin-top: -2rem;
  padding: 0 !important;
  @media (max-width: 1100px) {
    margin-top: 0;
  }
`;

function TopHeader() {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <>
      <Above breakpoint="md">
        <ChannelSelectorTarget />
      </Above>
      <HeaderContainer>
        <HeaderItemsContainer className="top-bar">
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <TopNav left style={{ marginLeft: '15px' }}>
                  <UspBar />
                </TopNav>
              ) : (
                <UspBar />
              )
            }
          </Above>
          <Above breakpoint="md">
            {matches =>
              matches && (
                <TopNav center>
                  <Query query={PhoneNumberFetch}>
                    {({ loading, error, data }) => {
                      if (loading) return null;
                      if (error) return `Error!: ${error}`;
                      if (!data?.category?.content) return null;
                      const tagReplacement = data.category.content.replace(
                        '<p>',
                        '<span>'
                      );
                      return (
                        <Phone>
                          <FontAwesomeIcon
                            icon={['fal', 'mobile-android-alt']}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: tagReplacement
                            }}
                          />
                        </Phone>
                      );
                    }}
                  </Query>
                </TopNav>
              )
            }
          </Above>
          <Above breakpoint="md">
            {matches => (
              <TopNav searchOpen={searchOpen} right>
                {matches && (
                  <StyledTopNavRight>
                    <ChannelSelector />
                    <TopNavLinks>
                      {/* <StyledLink to="/">{t('Customer Service')}</StyledLink> */}
                      <MyPagesLink to={routes.myPages.path}>
                        <span>{loggedIn ? t('My Pages') : t('Log in')}</span>
                      </MyPagesLink>
                    </TopNavLinks>
                  </StyledTopNavRight>
                )}
                <ChangeStore isSkiStore={selectedChannel.id === 1}>
                  {selectedChannel.id === 1 ? (
                    <a href="https://www.mtbstore.se">
                      <MtbStoreWhite />
                    </a>
                  ) : (
                    <a href="https://www.skistore.se">
                      <SkiStoreWhite />
                    </a>
                  )}
                </ChangeStore>
              </TopNav>
            )}
          </Above>
        </HeaderItemsContainer>
      </HeaderContainer>
    </>
  );
}

export default function Header() {
  const { selectedChannel } = useContext(ChannelContext);
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const mobileResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 3
    }
  });

  const [isScrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    if (typeof window !== 'undefined') {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollTop >= 140 && !isScrolled) {
        setScrolled(true);
      } else if (scrollTop < 80 && isScrolled) {
        setScrolled(false);
      }
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', rafThrottle(handleScroll));
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isScrolled]);

  return (
    <>
        <div>
            <Helmet>
                <script async src="https://cdn.pji.nu/libs/pjpixel/pjpixel.min.js"></script>
                <script>
                {`
                    window.addEventListener('load', function () {
                    pjpixel.init('ab915591-4a2a-4972-8b80-667fad7fa10e', { clientId: 'dc249961d3d54d1aa6b2fb890b3fd4c3' });
                    });
                `}
                </script>
            </Helmet>
            {/* Your component content goes here */}
        </div>


      <TopHeader categories={result} />
      <MainHeader className={isScrolled ? 'scrolled' : ''}>
        <Below breakpoint="md">
          <MobileMenu data={mobileResult.data} />
        </Below>
        <MaxedWidth className="logo-row">
          <Below breakpoint="md">
            <TopNav>
              <DrawerTrigger preventOverflow={true} id="menu-drawer">
                {drawer => (
                  <MenuButton
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <IconContainer>
                      <Menu />
                    </IconContainer>
                  </MenuButton>
                )}
              </DrawerTrigger>
            </TopNav>
          </Below>
          <StyledLogo className={isScrolled ? 'scrolled' : ''}>
            <Link className="logo" to="/">
              {selectedChannel.id === 1 ? <SkiStoreLogo /> : <MtbStoreLogo />}
            </Link>
          </StyledLogo>
          <Above breakpoint="md">
            <SearchNav>
              <SearchBar />
            </SearchNav>
          </Above>
          <Buttons className={isScrolled ? 'scrolled' : ''}>
            <FavouriteCount />
            <CartButton />
          </Buttons>
        </MaxedWidth>
        <Above breakpoint="md">
          <MaxedWidthCategories>
            <CategoryMenu data={result.data} />
          </MaxedWidthCategories>
        </Above>
        <Below breakpoint="md">
          <>
            <SearchNav className={isScrolled ? 'scrolled' : ''}>
              <SearchBar />
            </SearchNav>

            <ChannelSelectorTarget />
          </>
        </Below>
      </MainHeader>
      <Favourites isScrolled={isScrolled} />
      <CartFlyout isScrolled={isScrolled} />
    </>
  );
}
