import { useMutation } from '@apollo/react-hooks';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import uniqueId from '@jetshop/core/helpers/uniqueId';
import t from '@jetshop/intl';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import { toast } from 'react-toastify';
import { addToCart } from '../Cart/addToCart.gql';
import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';

const AddButton = styled('button')`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-top: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  transition: all ease 0.3s;
  padding: 0.25rem;
  width: 100%;
  display: block;
  :hover {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

/**
 *
 * @param {object} props
 * @param {import('@jetshop/core/hooks/ProductList').ProductListProduct} props.product
 */
export function AddToCart({ product }) {
  const { cartId, setCartId } = useContext(CartIdContext);
  const articleNumber = product.variant?.articleNumber || product.articleNumber;
  const price = {
    price: product.variant?.price || product.price,
    previousPrice: product.variant?.previousPrice || product.previousPrice
  };

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: 1
      }
    },
    onCompleted: data => {
      toast(
        <ProductToastWrapper
          selectedVariation={product.variant}
          product={product}
          quantity={1}
          price={price}
        />,
        {
          autoClose: 2000,
          toastId: uniqueId()
        }
      );
      addToCartSuccess({ cartId, setCartId })({ data });
    }
  });

  return <AddButton onClick={() => add()}>{t('Add to cart')}</AddButton>;
}
