import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';

const StartPageHeroWrapper = styled('div')`
  height: 600px;
  ${({ theme }) => theme.below.xl} {
    padding: 0px;
  }
  ${({ theme }) => theme.below.md} {
    padding: 0px;
    height: 400px;
  }
`;
const Container = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  text-align: left;
  padding: 1rem;
`;
const Heading = styled('h1')`
  font-size: 35px;
  text-transform: uppercase;
  line-height: 1.2;
  max-width: 450px;
  margin: 0 0 35px 0;

  color: ${props => (props.color ? props.color : 'black')};

  ${({ theme }) => theme.above.md} {
    width: 50%;
  }
  ${({ theme }) => theme.below.md} {
    font-size: 25px;
    max-width: 300px;
  }
`;

const StyledButton = styled('a')`
  display: inline-block;
  color: #fff;
  background: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  padding: 10px 50px;
  font-weight: bold;
  font-size: 16px;
  transition: all, 0.2s ease;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const HeroImage = styled(Image)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Positioner = styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  padding: 20px;
  ${({ theme }) => theme.below.md} {
    padding: 1rem;
  }

  &.left {
    left: 0;
    right: auto;
    text-align: left;
  }
  &.right {
    right: 0;
    left: auto;
    text-align: right;
    h1 {
      margin-left: auto;
    }
  }
  &.center {
    right: 0;
    left: 0;
    text-align: center;
    h1 {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.top {
    top: 10%;
    transform: none;
    bottom: auto;
  }
  &.bottom {
    top: auto;
    transform: none;
    bottom: 10%;
  }
  &.middle {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
`;

const StartPageHero = ({
  heading,
  buttonText,
  imageSrc,
  url,
  vertical,
  horizontal,
  color
}) => (
  <StartPageHeroWrapper>
    <HeroImage fillAvailableSpace={true} src={imageSrc}>
      <Container>
        <Positioner className={vertical + ' ' + horizontal}>
          <Heading color={color ? color : false}>{heading}</Heading>
          {url && buttonText && (
            <StyledButton href={url}>{buttonText}</StyledButton>
          )}
        </Positioner>
      </Container>
    </HeroImage>
  </StartPageHeroWrapper>
);

export default StartPageHero;
