import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import LoadingPage from '../LoadingPage';
import StartPageCampaign from './Content/StartPageCampaign';
import StartPageCategories from './Content/StartPageCategories';
import StartPageHero from './Content/StartPageHero';
import StartPageProductGrid from './Content/StartPageProductGrid';
import startPageQuery from './StartPageQuery.gql';
import { Banner, Heading } from './Content/BasicComponents';
import StartPageBox from './Content/StartPageBox';
import StartPageRenderer from './StartPageRenderer/StartPageRenderer';
import StartPageArticle from './Content/StartPageArticle';
import StartPagePush from './Content/StartPagePush';
import StartPageInfo from './Content/StartPageInfo';
import StartPageBrands from './Content/StartPageBrands';
import Instagram from '../Instagram/Instagram';
import StartPageCategoryBox from './Content/StartPageCategoryBox';
import HelloRetailRecommendations from '../HelloRetail';
import { LipscoreCompanyReviews } from '../Lipscore/LipscoreCompanyReviews';

const startPageComponents = {
  Categories: StartPageCategories,
  Campaign: StartPageCampaign,
  Hero: StartPageHero,
  Banner: Banner,
  Heading: Heading,
  Box: StartPageBox,
  Article: StartPageArticle,
  Push: StartPagePush,
  Info: StartPageInfo,
  SelectedBrands: StartPageBrands,
  CategoryBox: StartPageCategoryBox,
  HelloRetail: HelloRetailRecommendations,
};

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RecommendedWrapper = styled('div')`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const MainSectionWrapper = styled('div')`
  width: 100%;
  align-self: center;
  ${({ theme }) => theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StartPage = ({ location: { pathname, key }, startPageId }) => {
  return (
    <Fragment>
      <StartPageWrapper>
        <Query
          variables={startPageId && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {(result) => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;

            if (
              data.startPage &&
              data.startPage.items &&
              data.startPage.items.length > 0
            ) {
              return (
                <>
                  <StartPageRenderer
                    result={result}
                    startPageComponents={startPageComponents}
                    ProductGrid={StartPageProductGrid}
                    LoadingPage={LoadingPage}
                  />
                  <Instagram />
                  <LipscoreCompanyReviews />
                </>
              );
            } else return null;
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
