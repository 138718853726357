import * as React from 'react';
import Checkbox from '@jetshop/ui/Checkbox/Checkbox';
import styled from 'react-emotion';
export const Radio = styled(Checkbox)`
  display: flex;
  & span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`;
const CountryFlag = styled('img')`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;
const CheckboxWrapper = styled('div')`
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-child {
      margin-bottom: 5px;
    }
  }
`;

const CheckboxGroup = ({
  selectedItem,
  items,
  handleChange,
  groupLabel,
  title
}) => {
  if (title === 'Currency') {
    return null;
  } else {
    return (
      <CheckboxWrapper>
        {items.map(item => (
          <div>
            <CountryFlag
              src={`https://countryflags.jetshop.io/${
                item.val === 'Svenska' ? 'SE' : 'GB'
              }/flat/32.png`}
              alt="Country Flag"
            />
            <Radio
              type="radio"
              key={item.val}
              value={item.val}
              tabIndex={0}
              checked={selectedItem === item.val ? true : false}
              label={item.label}
              onChange={() => handleChange(item.val)}
            />
          </div>
        ))}
      </CheckboxWrapper>
    );
  }
};

export default CheckboxGroup;
