import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect, useRef } from 'react';
import styled, { css, cx } from 'react-emotion';
import { Price } from '../Price';
import { ProductCard } from './ProductCard';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';
import ScrollContainer from 'react-indiana-drag-scroll';

export const priceStyle = css`
  ${Price.Wrapper} {
    display: flex;
  }
  ${Price.New} {
    color: #ff0000;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${Price.Old} {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -15px;
  margin-left: -15px;
  ${({ theme }) => theme.below.md} {
    margin-left: -3px;
    margin-right: -3px;

    &.slider-mobile {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }

      > .product-card {
        flex: none;
        width: 48%;
      }
    }
  }

  ${priceStyle};

  .product-card {
    line-height: 19px;
    min-width: 0;
    padding: 0 15px 15px 15px;
    width: 50%;

    ${({ theme }) => theme.below.md} {
      padding: 0 8px 8px 8px;
    }

    ${({ theme }) => theme.above.md} {
      width: 33.333%;
    }
    ${({ theme }) => theme.above.lg} {
      width: 20%;
    }
    a {
      margin: 0;
    }
  }
`;

const ScrollContainerWrapper = styled(ScrollContainer)`
  --prod-width: 20%;
  ${({ theme }) => theme.below.lg} {
    --prod-width: 33.333%;
  }
  ${({ theme }) => theme.below.md} {
    --prod-width: 50%;
  }

  width: 100%;
  .innerWrapper {
    display: flex;
    width: calc(var(--prod-width) * ${(props) => props.products});
    > li {
      line-height: 19px;
      min-width: 0;
      padding: 0 15px 15px 15px;
      width: 100%;

      ${({ theme }) => theme.below.md} {
        padding: 0 8px 8px 8px;
      }

      a {
        margin: 0;

        .new-price {
          color: #ff0000;
        }
        .old-price {
          color: #878787;
        }
      }
    }
  }

  &.flyout {
    --prod-width: 70%;
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  sliderMobile,
  slider = null,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <>
      {slider ? (
        <ScrollContainerWrapper
          className={'scroll-container'}
          vertical={true}
          horizontal={true}
          products={products.length}
          className={slider === 'flyout' ? slider : ''}
        >
          <div className="innerWrapper">
            {products.map((product, index) => {
              return (
                <ProductCard
                  key={index + ':' + product.articleNumber}
                  product={product}
                  style={{ opacity: loading ? 0.5 : 1 }}
                  list={listName}
                  {...rest}
                >
                  <Favourite
                    product={product}
                    style={{
                      position: 'absolute',
                      padding: '0.5em',
                      fontSize: '1.5em',
                      top: 0,
                      right: '0.5em',
                    }}
                  />
                </ProductCard>
              );
            })}
          </div>
        </ScrollContainerWrapper>
      ) : (
        <Wrapper
          data-testid="product-grid"
          className={
            cx('product-grid', className) +
            (sliderMobile ? ' slider-mobile' : null)
          }
        >
          {products.map((product, index) => {
            return (
              <ProductCard
                key={index + ':' + product.articleNumber}
                product={product}
                style={{ opacity: loading ? 0.5 : 1 }}
                list={listName}
                {...rest}
              >
                <Favourite
                  product={product}
                  style={{
                    position: 'absolute',
                    padding: '0.5em',
                    fontSize: '1.5em',
                    top: 0,
                    right: '0.5em',
                  }}
                />
              </ProductCard>
            );
          })}
        </Wrapper>
      )}
    </>
  );
}

export default ProductGrid;
