import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import styled from 'react-emotion';

const Article = styled('section')`
  a {
    text-decoration: none;
    display: block;
    margin-bottom: 30px;
  }
`;

const ArticleImage = styled(Image)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
`;

const ArticleContent = styled('div')`
  color: #000;
  padding: 0 20px;

  h3 {
    text-align: center;
    color: ${props => props.theme.colors.primary};
    font-size: 1rem;
    margin-bottom: 5px;
  }
  p {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }
`;

const StartPageArticle = ({ heading, text, url, imageSrc }) => (
  <Article className="articleWrapper">
    <a href={url}>
      <ArticleImage cover={true} aspect="5:3" src={imageSrc} />
      <ArticleContent className="articleContent">
        <h3>{heading}</h3>
        <p>{text}</p>
      </ArticleContent>
    </a>
  </Article>
);

export default StartPageArticle;
