import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css } from 'react-emotion';
import { Price } from '../Price';
import { theme } from '../Theme';

const wrapperStyling = css`
  position: relative;
  list-style-type: none;
  margin: 1.5rem 1rem 0.5rem 1rem;
  border-top: 1px solid ${theme.colors.lightgrey};
  max-width: calc(100% - 2rem);
  padding-top: 0.5rem;
  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: space-between;
    background: transparent;
    transition: ease 0.3s;
    width: 100%;
  }

  .product-image-wrapper {
    width: 25%;
  }

  .product-card-detail {
    background: transparent;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    width: 75%;
    padding-left: 0.5rem;
    h3 {
      font-size: 14px;
      text-overflow: ellipsis;
      line-height: 1.2;
      overflow: hidden;
      font-weight: bold;
      padding-right: 0.5rem;
      margin-bottom: 10px;
      text-transform: none;
      ${theme.below.md} {
        font-size: 12px;
        white-space: initial;
        position: relative;

        max-height: 33px;
        overflow: hidden;
        display: block;
        width: calc(100% - 15px);
        &:after {
          content: '';
          position: absolute;
          pointer-events: none;
          right: 0;
          height: 16px;
          width: 50px;
          bottom: -1px;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(hsla(0, 0%, 100%, 0)),
            to(#fff)
          );
          background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
        }
      }
    }

    .price-wrapper {
      color: ${theme.colors.black};
      font-weight: bold;
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: 0;
      text-align: right;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      ${theme.below.md} {
        font-size: 12px;
      }
    }
  }
`;

export function FavouriteProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;

  return (
    <Tag
      className="product-card"
      css={[wrapperStyling, className]}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} {...linkProps}>
        <div className="product-image-wrapper">
          {hasImages ? (
            <Image
              className="product-image"
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
            >
              <Badges badges={product.badges} />
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </div>

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
          </header>
          <div className="price-wrapper">
            <Price
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </div>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
