import uniqueId from '@jetshop/core/helpers/uniqueId';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import { toast } from 'react-toastify';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Product } from './Product';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';

const FavouritesWrapper = styled('div')`
  position: relative;
  z-index: 4;
  ${theme.below.md} {
    z-index: 9;
  }
  &.top {
    & > div {
      height: calc(100vh - 181px);
      margin-top: 181px;
      ${theme.below.md} {
        height: 100%;
        margin-top: 0;
        max-width: 90%;
      }
    }
  }
  & > div {
    height: calc(100vh - 107px);
    margin-top: 107px;

    ${theme.below.md} {
      height: 100%;
      margin-top: 0;
      max-width: 90%;
    }
  }
`;

const LightText = styled('span')`
  color: ${theme.colors.mediumgrey};
  font-size: 14px;
  font-family: ${theme.fonts.body};
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
`;

const FlexedList = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ClearListButton = styled('button')`
  background: #fff;
  color: ${theme.colors.mediumgrey};
  font-size: 14px;
  letter-spacing: 0.25px;
  padding: 0.5rem;
  text-transform: uppercase;
  width: 100%;
`;

export const Favourites = ({ isScrolled }) => (
  <FavouritesWrapper className={isScrolled ? 'scrolled' : 'top'}>
    <DrawerTarget id="favourites-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen} right>
          <RenderFavourites {...drawer} />
        </Drawer>
      )}
    </DrawerTarget>
  </FavouritesWrapper>
);

function RenderFavourites({ hideTarget }) {
  const { products, loading } = useProductListItems();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;
  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        toast(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>,
          {
            toastId: uniqueId(),
            autoClose: 2000
          }
        );
      }
    }
  );

  const loadingInitialRender = products.length === 0 && loading;

  const count = products.length;

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading && 'loading')}>
        <h2>{t('Favourites')}</h2>
        <p>{t('Loading')}...</p>
      </MaxWidth>
    );
  }

  return (
    <MaxWidth className={cx(container, loading && 'loading')}>
      <FlexedList>
        <h2>{t('Favourites')}</h2>
        <LightText>
          {t(
            `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
            { count }
          )}
        </LightText>
        <ul className="product-grid">
          {products.length > 0 ? (
            products.map((product, index) => {
              return (
                <li
                  key={product.variant?.articleNumber || product.articleNumber}
                  data-valid={product.validation.status}
                >
                  <Product product={product} hideTarget={hideTarget} />
                </li>
              );
            })
          ) : (
            <p>{t('No products in favourite list')}</p>
          )}
        </ul>
      </FlexedList>

      <div className="add-clear">
        {products.length > 0 ? (
          <>
            <Button
              style={{ marginTop: '2em' }}
              onClick={addToCart}
              loading={mutationLoading}
              className="add-all"
              disabled={validItemCount === 0}
            >
              {validItemCount > 0
                ? mutationLoading
                  ? t('One moment…')
                  : t(
                      '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                      { productCount: validItemCount }
                    )
                : t('No valid items to add to cart')}
            </Button>
            <ClearList>{t('Clear list')}</ClearList>
          </>
        ) : null}
      </div>
    </MaxWidth>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <ClearListButton secondary onClick={clear}>
      {children}
    </ClearListButton>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const container = css`
  padding: 0 !important;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  display: block;
  &.loading {
    opacity: 0.6;
  }

  h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 1rem;
  }

  p.loadingProducts {
    color: ${theme.colors.grey};
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    > span {
      text-transform: uppercase;
      display: block;
      text-align: center;
    }
    li {
      width: 100%;
      > .product-card {
        width: 100%;
      }
    }
    > .product-card {
      line-height: 19px;
      min-width: 0;
      margin: ${theme.space[1]};
      width: 100%;

      ${theme.above.md} {
        width: 100%;
      }
      ${theme.above.lg} {
        width: 100%;
      }
    }
  }
  .product-card {
    a {
      margin: 0;
      border: 0;
    }
  }
  .product-card-detail,
  .product-card,
  .product-card a {
    background: white;
  }
  li:not([data-valid='valid']) .product-card {
    background: rgba(255, 255, 255, 0.2);
    a {
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .select-variant {
    margin: 0;
    padding: 1em;
    position: relative;
    z-index: 20;
    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .add-clear button {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
    margin-bottom: 1rem;
    width: calc(100% - 2rem);
  }

  .add-all {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    text-transform: uppercase;
    transition: all ease 0.3s;
    width: 100%;
    :hover {
      background: ${theme.colors.primaryHover};
    }
  }

  .new-price,
  .old-price {
    display: inline-block;
  }
  .old-price {
    margin-left: 0.5em;
  }
`;

const addAllToastStyles = css`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
  font-size: 16px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;
