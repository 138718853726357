import React from 'react';
import styled from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';

const Info = styled('section')`
  text-decoration: none;
  display: block;
  background: ${props => props.theme.colors.primary};
  color: #000;
  height: auto;
  padding: 40px 40px 120px 40px;
  position: relative;

  ${({ theme }) => theme.below.md} {
    width: 100% !important;
    text-align: center;
    padding: 15px 10px;
  }

  h3 {
    font-size: 25px;
    ${({ theme }) => theme.below.md} {
      font-size: 20px;
    }
    a {
      color: #000;
      text-decoration: none;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.4;
  }
`;

const InfoButton = styled('a')`
  background: ${props => props.theme.colors.black};
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  max-width: 160px !important;
  margin: auto;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background: #333 !important;
  }
`;

const StartPageInfo = ({ heading, text, url, buttonText, color }) => (
  <Info>
    <h3>
      <a href={url} style={{ color: color }}>
        {heading}
      </a>
    </h3>

    <Above breakpoint="md">
      <>
        <p style={{ color: color }}>{text}</p>
        {buttonText && <InfoButton href={url}>{buttonText}</InfoButton>}
      </>
    </Above>
  </Info>
);

export default StartPageInfo;
