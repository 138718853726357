import React from 'react';

import styled from 'react-emotion';
import { TrendLink } from '../../ui/Button';

const StyledBanner = styled(TrendLink)`
  display: block;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 17px;
  height: 60px;
  line-height: 60px;
  padding: 0 50px;
  ${({ theme }) => theme.below.lg} {
    height: auto;
    line-height: 1.2;
    line-height: auto;
    font-size: 14px;
    padding: 10px 15px;
  }
`;
const StyledHeading = styled('h2')`
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 25px;
  ${({ theme }) => theme.below.md} {
    font-size: 20px;
    margin-top: 20px;
  }
`;

export const Banner = ({ text, url, color }) => (
  <StyledBanner to={url} style={{ color: color }}>
    {text}
  </StyledBanner>
);

export const Heading = ({ text }) => <StyledHeading>{text}</StyledHeading>;
