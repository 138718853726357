import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';

const SvgButtonWrapper = styled('button')`
  padding: 16px;
  margin: -16px;
  background: inherit;
  display: flex;
  svg {
    use {
      fill: black;
    }
  }
`;

const CloseButton = ({ onClick, className }) => (
  <SvgButtonWrapper onClick={onClick} className={className}>
    <Cross />
  </SvgButtonWrapper>
);

export default CloseButton;
