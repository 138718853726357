import React, { useContext, useState, useRef } from 'react';
import styled from 'react-emotion';
import MobileCategories from './Categories/MobileCategories';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import CloseButton from '../../ui/CloseButton';
import { Link } from 'react-router-dom';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as MtbStoreWhite } from '../../../svg/mtbstore_white.svg';
import { ReactComponent as SkiStoreWhite } from '../../../svg/skistore_white.svg';
import { ChannelSelectorTrigger } from './ChannelSelector/ChannelSelector';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const StyledCloseButton = styled(CloseButton)`
  margin-right: 0;
  margin-left: auto;
`;

const NavBarHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  height: 45px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 45px);
`;

const SecondaryMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: grey;
  ${theme('below.md')} {
    background: ${theme('colors.tablegrey')};
  }
`;

const SecondaryMenuItem = styled.li`
  font-family: ${theme('fonts.primary')};
  font-size: 13px;
  font-weight: 500;
  list-style: none;
  display: block;
  padding: 20px;
  a,
  :active,
  :hover {
    text-decoration: none;
    color: ${theme('colors.black')};
  }
  button {
    background: none;
    color: ${theme('colors.black')};
  }
`;

const BottomContent = styled('div')``;

const ChangeStore = styled('div')`
  align-items: center;
  background: ${({ isSkiStore }) => (isSkiStore ? '#5B702E' : '#f8b133')};
  color: white;
  cursor: pointer;
  display: flex;
  height: 48px;
  transition: all ease 0.3s;
  :hover {
    background: ${({ isSkiStore }) => (isSkiStore ? '#4B601E' : '#d0942b')};
  }

  a {
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  svg {
    width: 100px;
    margin: auto;
    path,
    g,
    use {
      fill: white;
    }
  }
`;

const InnerWrapper = styled('div')`
  height: 100%;
`;
const CategoryWrap = styled('div')``;

export default function MobileMenu({ data }) {
  const [locked, setLocked] = useState(false);
  const { loggedIn, logOut } = useAuth();
  const [active, setActive] = useState(false);
  const { routes } = useShopConfig();
  const [openCat, setOpenCat] = useState(null);
  const { channels, updateChannel, selectedChannel } = useContext(
    ChannelContext
  );

  const menuWrapper = useRef(null);
  if (!(data && data.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <InnerWrapper locked={locked} ref={menuWrapper}>
            <NavBarHeader>
              <StyledCloseButton onClick={drawer.hideTarget} />
            </NavBarHeader>
            <Scroll>
              <CategoryWrap>
                <MobileCategories
                  menuWrapper={menuWrapper}
                  locked={locked}
                  setLocked={setLocked}
                  categories={data.categories}
                  closeMenu={drawer.hideTarget}
                  openCat={openCat}
                  setOpenCat={setOpenCat}
                />
              </CategoryWrap>
              <BottomContent>
                <SecondaryMenu>
                  <SecondaryMenuItem>
                    <ChannelSelectorTrigger />
                  </SecondaryMenuItem>
                  <SecondaryMenuItem>
                    <Link to="/" onClick={drawer.hideTarget}>
                      {t('Customer Service')}
                    </Link>
                  </SecondaryMenuItem>
                  <SecondaryMenuItem>
                    <Link onClick={drawer.hideTarget} to={routes.myPages.path}>
                      {loggedIn ? t('My Pages') : t('Log in')}
                    </Link>
                  </SecondaryMenuItem>
                </SecondaryMenu>
                <ChangeStore isSkiStore={selectedChannel.id === 1}>
                  {selectedChannel.id === 1 ? (
                    <a href="https://www.mtbstore.se">
                      <MtbStoreWhite />
                    </a>
                  ) : (
                    <a href="https://www.skistore.se">
                      <SkiStoreWhite />
                    </a>
                  )}
                </ChangeStore>
              </BottomContent>
            </Scroll>
          </InnerWrapper>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
