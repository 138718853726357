import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import { campaignQuery } from './components/ProductPage/ProductPackage/ProductPackageQuery.gql';
import { addwishTracker } from '@jetshop/flight-addwish';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'skistore',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: campaignQuery,
      },
    ],
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: false,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {},
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  trackers: [addwishTracker('795ACD6BE2D3A87303FAA70B47DFE6FE')],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  /** Old filter API will be removed in 5.0.0 */
  useNewFilterAPI: false,
  deprecations: {
    /** Will be removed in 6.0.0 */
    useOldProductVariants: false,
  },
};
