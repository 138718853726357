import React, { useContext } from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'react-emotion';
import { SearchField } from '@jetshop/ui/Search';
import theme from '@jetshop/ui/utils/theme';
import t from 'format-message';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const PosedSearchBarContainer = styled(
  posed('div')({
    enter: {
      marginTop: 0,
      opacity: 1,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    },
    exit: {
      marginTop: '-30px',
      opacity: 0,
      transition: {
        duration: 200,
        ease: 'linear'
      }
    }
  })
)`
  position: relative;
  width: 480px;
  margin: 0 auto;
  ${theme('below.md')} {
    width: 100%;
  }
  svg {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${theme('below.md')} {
      right: calc(10% - 30px);
    }
    use {
      fill: #878787;
    }
  }
`;

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;
`;

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    display: flex;
    height: 2rem;
    width: 100% !important;
    justify-content: center;
    input {
      border: 0;
      background: #f3f3f3;
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      border-radius: 2rem;
      font-size: 14px;
      letter-spacing: 0.5px;
      color: ${theme('colors.grey')};
      padding-left: 2.5rem;
      ${theme('below.md')} {
        padding-left: 2.25rem;
        font-size: 16px;
      }
    }
  }
  ${SearchField.Cancel} {
    display: none;
  }

  ${SearchField.Flyout} {
    text-align: left;
    top: calc(100% + ((54px - 100%) / 2));
    li {
      font-size: 16px;
      transition: all ease 0.3s;
      &:hover {
        background: ${theme('colors.tablegrey')};
      }
    }
    ${({ theme }) => theme.below.md} {
      top: calc(100% + ((52px - 100%) / 2));
      width: 100%;
      border-left: none;
      border-right: none;
      background: #f7f7f7;
      width: auto;
      left: -20px;
      right: -20px;
      max-height: 80vh;
      overflow-y: scroll;
    }
    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      padding: 0.5rem;
    }
    a {
      color: inherit;
      display: block;
    }
  }
`;

const StyledSearch = styled(Search)`
  color: #bbb;
  height: 1.25rem;
  position: absolute;
  width: 1.25rem;
  z-index: 1;
  left: -88%;
`;

const SearchBar = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <PoseGroup flipMove={true}>
      <PosedSearchBarContainer key={'searchBarPosed'}>
        <InnerSearchBar>
          <StyledSearch />
          <StyledSearchField
            autocompleteQuery={AutocompleteQuery}
            placeholder={
              selectedChannel.id === 1
                ? 'Sök på Skistore...'
                : 'Sök på MtbStore...'
            }
            onCancel={() => null}
          />
        </InnerSearchBar>
      </PosedSearchBarContainer>
    </PoseGroup>
  );
};

export { SearchBar };
