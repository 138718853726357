import React from 'react';
import styled from 'react-emotion';

import { ReactComponent as InstagramIcon } from '../../../svg/Instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../svg/Facebook.svg';

const Wrapper = styled('section')`
  margin-right: 0;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3.5rem;
    flex-direction: row;
    justify-content: flex-start;
  }

  li {
    margin-right: 0.8rem;
  }

  a.social-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const LogoWrapper = styled('span')`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SocialLinks = () => (
  <Wrapper>
    <ul>
      <li>
        <a className="social-links" href="/">
          <LogoWrapper>
            <FacebookIcon />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a className="social-links" href="/">
          <LogoWrapper>
            <InstagramIcon />
          </LogoWrapper>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
