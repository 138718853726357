import t from '@jetshop/intl';
import React from 'react';
import { css } from 'react-emotion';
import { FavouriteProductCard } from './FavouriteProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { ReactComponent as TrashSVG } from '../../svg/trash.svg';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { theme } from '../Theme';

export function Product({ product, loading, children }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (
    product.isVariant &&
    product.variant.images &&
    product.variant.images.length > 0
  ) {
    productForDisplay.images = product.variant.images;
  }

  return (
    <>
      <FavouriteProductCard product={productForDisplay} as="div">
        {product.hasVariants && (
          <div className="select-variant">
            <SelectVariant product={product} />
          </div>
        )}
        {product.validation.status === 'valid' ? (
          <AddToCart product={product} />
        ) : (
          <InvalidProduct validation={product.validation} />
        )}
        <RemoveFromList
          articleNumber={product.articleNumber}
          variant={product.variant}
        />
      </FavouriteProductCard>
    </>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button
      className={removeStyles}
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    >
      <TrashSVG />
    </button>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}

const removeStyles = css`
  position: absolute;
  top: 0.5rem;
  right: 0rem;
  background: white;
  border-radius: 50%;
  svg {
    height: 1.25em;
    width: 1.25em;
  }
`;

const invalidProductStyles = css`
  background: ${theme.colors.lightgrey};
  color: ${theme.colors.black};
  cursor: not-allowed;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin-top: 0.5rem;
  opacity: 0.4;
  padding: 0.25rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;
