import React from 'react';
const StartPageItems = ({
  rowItems,
  startPageComponents,
  ProductGrid,
  index,
}) => {
  const firstItem = rowItems[0].item;
  if (rowItems.length > 0 && firstItem.content && firstItem.name !== 'Slide') {
    return rowItems.map(({ item }, itemIndex) => {
      if (item.content) {
        let content = null;
        try {
          content = JSON.parse(item.content);
        } catch (error) {
          console.warn(`Unable to parse JSON content for ${item.name}`);
        }
        if (content) {
          const Component = startPageComponents[item.name];
          return Component ? (
            <Component
              {...Object.assign({ key: itemIndex }, content, {
                imageSrc: item.image && item.image.url,
                linkUrl: item.linkUrl,
              })}
            />
          ) : null;
        }
      }
    });
  } else if (firstItem.articleNumber) {
    const products = rowItems.map(({ item }) => item);
    return <ProductGrid key={index} products={products} />;
  } else if (firstItem.name === 'Slide') {
    const slides = rowItems.map(({ item }) => item);
    const Slider = startPageComponents['Slider'];
    return (
      <Slider device={JSON.parse(firstItem.content).device} slides={slides} />
    );
  } else return null;
};

export default StartPageItems;
