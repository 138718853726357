import React from 'react';
import styled from 'react-emotion';

import { useProductList } from '@jetshop/core/hooks/ProductList';
import theme from '@jetshop/ui/utils/theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FavouritesListButton = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #fff;
  position: relative;
  svg {
    width: 1em;
    height: 1em;
    fill: transparent;
    margin-bottom: 0;
  }
`;

const Count = styled('span')`
  align-items: center;
  background: ${theme('colors.primary')};
  border-radius: 50%;
  bottom: 1.25rem;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  justify-content: center;
  right: -0.6rem;
  position: absolute;
  width: 20px;
  ${theme('below.md')} {
    bottom: 0.5rem;
    font-size: 10px;
    height: 15px;
    width: 15px;
  }
`;

export function FavouriteCount() {
  const { count } = useProductList();

  return (
    <DrawerTrigger id="favourites-drawer">
      {drawer => (
        <FavouritesListButton
          onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
        >
          <FontAwesomeIcon icon={['fal', 'star']} />
          {count && count.length !== 0 ? <Count>{count}</Count> : null}
        </FavouritesListButton>
      )}
    </DrawerTrigger>
  );
}
