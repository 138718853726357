import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import SubMenuWrapper from './SubMenuWrapper';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  position: relative;
  margin-top: 0.5rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    font-weight: 900;
    padding: 0.9rem 1rem;
    margin: 0;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: all ease 0.3s;
    font-family: ${theme('fonts.secondary')};
    border-bottom: 5px solid ${theme('colors.black')};
    &.active {
      color: ${theme('colors.primary')};
      font-weight: bold;
      border-bottom: 5px solid ${theme('colors.primary')};
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(
                  cat =>
                    cat.id !== 437 &&
                    cat.id !== 372 && (
                      <MainMenuItem
                        key={cat.id}
                        // When mousing over a menu item, set it as the active nav
                        onMouseEnter={() => {
                          if (
                            cat.id !== 459 &&
                            cat.id !== 240 &&
                            cat.id !== 328
                          ) {
                            props.setActiveCategory(cat);
                          } else {
                            props.clearActiveCategories();
                          }
                        }}
                      >
                        <CategoryLink
                          // When following a category link, close the menu
                          onClick={props.clearActiveCategories}
                          category={cat}
                        >
                          {cat.name}
                        </CategoryLink>
                      </MainMenuItem>
                    )
                )
              : null}
          </ul>
          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default CategoryMenuContainer;
