import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect } from 'react';
import { API_KEY_SKI, API_KEY_MTB } from './LipscoreGlobalThingis';
import styled from 'react-emotion';
import { useScript } from '../../hooks/useScript';
import t from '@jetshop/intl';

const CompanyReviewWrapper = styled('div')`
  background: #f7f7f7;
  margin-bottom: -40px;
  .inner {
    h2 {
      text-align: center;
      margin-bottom: 10px;
    }
    max-width: 900px;
    padding: 50px 20px 20px 20px;
    margin: auto;
  }
`;

export const LipscoreCompanyReviews = ({}) => {
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.country?.code?.toLowerCase();
  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loaded) {
        if (window?.lipscore) {
          if (!window?.lipscore?.isInitialized()) {
            window.lipscoreInit = () => {
              lipscore.init({
                apiKey: selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB,
              });
            };
            window.lipscore.apiKey =
              selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB;
          }
          window?.lipscore?.initWidgets();
        }
      }
    }
  }, [loaded]);

  if (!loaded || error) return null;

  return (
    loaded && (
      <CompanyReviewWrapper>
        <div className="inner">
          <h2>{t('Reviews')}</h2>
          <div
            className="lipscore-service-review-testimonial"
            ls-widget-height="140px"
            ls-widget-width="100%"
          />
        </div>
      </CompanyReviewWrapper>
    )
  );
};
