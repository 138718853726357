import ProductVariationContext from '@jetshop/core/components/ProductVariationProvider/ProductVariationContext';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import React, { useContext } from 'react';
import { css } from 'react-emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const favourite = css`
  button {
    display: block;
    background: transparent;
    &:focus {
      outline: none;
    }
    svg {
      &.active {
        fill: currentColor;
      }
      width: 1em;
      height: 1em;
    }
  }
`;

export function Favourite({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;

  const variationContext = useContext(ProductVariationContext);
  const selectedVariation =
    product.variant || variant || variationContext?.selectedVariation;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <div className={favourite} {...rest}>
      <button onClick={toggleWithoutBubble}>
        {isInList ? (
          <FontAwesomeIcon icon={['fas', 'star']} />
        ) : (
          <FontAwesomeIcon icon={['fal', 'star']} />
        )}
      </button>
    </div>
  );
}
