import React, { useEffect, useState, useContext } from 'react';
import striptags from 'striptags';
import theme from '@jetshop/ui/utils/theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useQuery } from '@apollo/react-hooks';
import uspQuery from './UspQuery.gql';

import styled from 'react-emotion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UspBarContainer = styled('ul')`
  display: flex;

  &.header-usp {
    ${theme('below.sm')} {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
    li {
      ${theme('below.sm')} {
        font-size: 9px;
        padding: 0 !important;
        span {
          margin: 0;
        }
        svg {
          display: none;
        }
      }
    }
  }
  li {
    color: ${theme('colors.black')};
    display: flex;
    margin-right: 1.5rem;
    align-items: center;
    &:last-of-type {
      margin-right: 0;
    }

    span {
      margin-left: 0.5rem;
    }
    svg {
      height: 14px;
      width: 12px;
      margin: 0;
    }
  }
  ${theme('below.md')} {
    width: 100%;
    background: ${theme('colors.mediumgrey')};
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 10px 0.5rem 10px;
    font-size: 11px;
    li {
      margin: 0;
      :first-of-type {
        padding-right: 0.75rem;
      }
      span {
        margin-left: 0.25rem;
      }
    }
  }
  ${theme('below.xs')} {
    flex-direction: column;
    padding-left: 1.25rem;
  }
`;

const UspBar = ({ productPage }) => {
  const { selectedChannel } = useContext(ChannelContext);
  let channelId = 176;
  if (selectedChannel.id === 1) {
    if (productPage) {
      channelId = 176;
    } else {
      channelId = 710;
    }
  } else {
    if (productPage) {
      channelId = 474;
    } else {
      channelId = 712;
    }
  }
  const { data } = useQuery(uspQuery, {
    variables: {
      id: channelId,
    },
  });
  if (!data) return null;

  let content = data.category.content;

  const clean = striptags(content, [], '\r').split('\n').filter(Boolean);

  return (
    <UspBarContainer
      className={productPage ? 'product-page-usp' : 'header-usp'}
    >
      {clean.map((usp, index) => (
        <React.Fragment key={index}>
          {usp && usp.length > 1 ? (
            <li>
              <FontAwesomeIcon icon={['fal', 'check']} />
              <span>{usp}</span>
            </li>
          ) : null}
        </React.Fragment>
      ))}
    </UspBarContainer>
  );
};

export default UspBar;
