import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

const Flyout = styled('div')`
  background-color: #fff;
  position: absolute;
  z-index: 5;
  top: 48px;
  right: 148px;
  z-index: 10;
  ${theme('below.md')} {
    right: calc(50% - (256px / 2));
    top: 25vh;
    z-index: 9;
  }
`;

const CountryFlag = styled('img')`
  height: 16px;
  margin-top: -2px;
  margin-right: 5px;
  ${theme('below.md')} {
    height: 15px;
  }
`;

export const ChannelSelectorTarget = () => (
  <ChannelContext.Consumer>
    {({ channels, selectedChannel, updateChannel }) => (
      <FlyoutTarget id="channel-selector">
        {({ hideTarget }) => (
          <Flyout>
            <Selector
              channels={channels}
              hideTarget={hideTarget}
              selectedChannel={selectedChannel}
              updateChannel={updateChannel}
            />
          </Flyout>
        )}
      </FlyoutTarget>
    )}
  </ChannelContext.Consumer>
);

export const ChannelSelectorTrigger = () => (
  <ChannelContext.Consumer>
    {({ selectedChannel }) => (
      <FlyoutTrigger id="channel-selector">
        {({ showTarget, hideTarget, isOpen }) => (
          <button
            onClick={isOpen ? hideTarget : showTarget}
            style={{ background: 'transparent' }}
          >
            <CountryFlag
              src={`https://countryflags.jetshop.io/${
                selectedChannel.language.name === 'Svenska' ? 'SE' : 'GB'
              }/flat/32.png`}
              alt="Country Flag"
            />
            {selectedChannel.language.name}
          </button>
        )}
      </FlyoutTrigger>
    )}
  </ChannelContext.Consumer>
);

export default () => {
  return (
    <>
      <ChannelSelectorTrigger />
    </>
  );
};
