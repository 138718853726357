import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { API_KEY_SKI, API_KEY_MTB } from './LipscoreGlobalThingis';
import { useScript } from '../../hooks/useScript';

export const LipscoreInit = () => {
  const location = useLocation();
  const path = location.pathname;
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.country?.code?.toLowerCase();
  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loaded) {
        if (window.lipscore) {
          if (!window.lipscore.isInitialized()) {
            window.lipscoreInit = () => {
              lipscore.init({
                apiKey: selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB,
              });
            };
            window.lipscore.apiKey =
              selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB;
          }
          window.lipscore.initWidgets();
        }
      }
    }
  }, [loaded, path]);

  return null;
}