import { useInView } from 'react-intersection-observer';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React, { useState } from 'react';
import { css } from 'react-emotion';
import { Price } from '../Price';
import { theme } from '../Theme';
import { LipscoreProductSmallRating } from '../Lipscore/LipscoreProductSmallRating';
import { useFauxPricing } from '../ProductPage/ProductPackage/useFauxPricing';
import { Form } from 'formik';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import styled from 'react-emotion';
import Button from '../ui/Button';
import t from '@jetshop/intl';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  list-style-type: none;
  padding: 0 0 30px 0;
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: transparent;
    width: 100%;
  }

  .product-image-wrapper {
    border: 1px solid ${theme.colors.tablegrey};
    padding: 20px;

    .no-product-image {
      > div {
        background: white !important;
        text-align: center;
      }
    }
  }

  .product-card-detail {
    background: transparent;
    padding: 0.75em;
    line-height: 1.35;
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 15px;
      font-weight: normal;
      margin-bottom: 5px;
      text-transform: uppercase;
      ${theme.below.md} {
        font-size: 12px;
        white-space: initial;
        position: relative;

        //max-height: 33px;
        overflow: hidden;
        display: block;
        width: 100%;
        &:after {
          content: '';
          position: absolute;
          pointer-events: none;
          right: 0;
          height: 16px;
          width: 50px;
          bottom: -1px;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(hsla(0, 0%, 100%, 0)),
            to(#fff)
          );
          background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);

          display: none;
        }
      }
    }

    h4 {
      font-weight: normal;
      font-size: 1em;
      color: ${theme.colors.black};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .faux-price-wrapper {
      font-weight: bold;
      font-size: 12px !important;
      & > div {
        display: flex;
        justify-content: start;
        margin: 0;
      }
      .new-price {
        font-size: 12px !important;
        color: #ff0000;
        margin-right: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .old-price {
        font-size: 12px !important;
        color: #878787;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .price-wrapper {
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      ${theme.below.md} {
        font-size: 12px;
      }
      > div {
        margin: 0 5px;
      }
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '3:4',
  forwardRef,
  as = 'li',
  children,
  bindings,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  const { fauxPrice } = useFauxPricing(product);

  return (
    <Tag
      className="product-card"
      css={[wrapperStyling, className]}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} {...linkProps}>
        <div className="product-image-wrapper" ref={ref}>
          {hasImages ? (
            <Image
              className="product-image"
              sizes={'200px'}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
            >
              <Badges badges={product.badges} />
            </Image>
          ) : (
            <Image
              className="no-product-image product-image"
              sizes={'200px'}
              aspect={imageAspect}
              src={transparentDataImg}
            />
          )}
        </div>

        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
            {/* <h4 className="sub-name">{product.subName || '\u00A0'}</h4> */}
          </header>
          {bindings ? (
            <div className={fauxPrice ? 'faux-price-wrapper' : 'price-wrapper'}>
              <Price
                price={fauxPrice ? fauxPrice : product.price}
                previousPrice={product.previousPrice}
              />
            </div>
          ) : (
            <div className="price-wrapper">
              <Price
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </div>
          )}

          {showed && product && (
            <LipscoreProductSmallRating product={product} onCard />
          )}
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
