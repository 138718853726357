import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import styled from 'react-emotion';

const CategoryBox = styled('section')`
  position: relative;
  a {
    text-decoration: none;
    overflow: hidden;
    position: relative;
    display: block;

    > div:first-child {
      transition: 0.3s ease;
      ${({ theme }) => theme.below.md} {
        padding-bottom: 100% !important;
      }
    }

    &:hover {
      > div:first-child {
        transform: scale(1.1);
      }
    }
  }
`;

const CategoryBoxImage = styled(Image)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CategoryBoxContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: absolute;
  padding-bottom: 25px;
  bottom: 0;
  left: 0;
  right: 0;

  h3 {
    max-width: 480px;
    font-size: 25px;
    text-align: center;
    line-height: 1.3;
    ${({ theme }) => theme.below.md} {
      font-size: 20px;
    }
  }
`;

const StartPageCategoryBox = ({ text, url, imageSrc, color }) => (
  <CategoryBox>
    <a href={url}>
      <CategoryBoxImage
        className="CategoryBoxImage"
        aspect={'4:3'}
        src={imageSrc}
        cover={true}
      />
      <CategoryBoxContent className="CategoryBoxContent">
        <h3 style={{ color: color }}>{text}</h3>
      </CategoryBoxContent>
    </a>
  </CategoryBox>
);

export default StartPageCategoryBox;
