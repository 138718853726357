import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import cartQuery from './CartQuery.gql';
import Badge from '../ui/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = styled('button')`
  background: transparent;
  position: relative;
  margin-left: 1.5rem;

  svg {
    max-width: 30px;
  }
  ${theme('below.md')} {
    margin-left: 1rem;
  }
`;

const CartLabel = styled('label')`
  display: block;
  font-size: 12px;
  color: #000;
  margin-top: 3px;
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }
`;

const CartIconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  svg {
    max-width: 30px;
    margin: 0;
    use {
      fill: #000;
    }
  }

  .badge {
    position: absolute;
    top: -4px;
    right: -4px;
  }
  ${theme('below.md')} {
    span {
      background: ${theme('colors.primary')} !important;
      display: flex;
      align-items: center;
      font-family: ${theme('fonts.primary')} !important;
      font-size: 10px;
      font-weight: bold;
      height: 15px;
      justify-content: center;
      line-height: 1 !important;
      padding-top: 1px;
      padding-left: 0.5px;
      position: absolute;
      width: 15px;
    }
    .badge {
      top: 0.4rem;
      right: 0;
    }
  }
`;

const CartCount = styled('span')`
  align-items: center;
  background: ${theme('colors.primary')};
  border-radius: 50%;
  bottom: 1.25rem;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  justify-content: center;
  right: -1rem;
  position: absolute;
  width: 20px;
`;

function CartButton() {
  return (
    <Fragment>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

          return (
            // Use a flyout for breakpoints above md
            // Else use a Drawer
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <DrawerTrigger id="cart-drawer">
                    {flyout => (
                      <Button
                        data-testid="header-cart"
                        onClick={
                          flyout.isOpen ? flyout.hideTarget : flyout.showTarget
                        }
                        className="cart-button"
                      >
                        <div
                          className="cart-button-inner"
                          data-testid="cart-button"
                        >
                          <FontAwesomeIcon icon={['fal', 'shopping-bag']} />
                          {itemsInCart && itemsInCart.length !== 0 ? (
                            <CartCount>{itemsInCart}</CartCount>
                          ) : null}
                        </div>
                      </Button>
                    )}
                  </DrawerTrigger>
                ) : (
                  <DrawerTrigger preventOverflow={true} id="cart-drawer">
                    {drawer => (
                      <Button
                        data-testid="header-cart"
                        onClick={
                          drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                        }
                      >
                        <CartIconContainer>
                          {itemsInCart > 0 && <Badge text={itemsInCart} />}
                          <FontAwesomeIcon icon={['fal', 'shopping-bag']} />
                        </CartIconContainer>
                      </Button>
                    )}
                  </DrawerTrigger>
                )
              }
            </Above>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButton;
