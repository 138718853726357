import React from 'react';

import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';

import { useQuery } from '@apollo/react-hooks';
import selectedBrandsQuery from './SelectedBrandsQuery.gql';

import styled from 'react-emotion';

const Brands = styled('section')`
  a {
    text-decoration: none;
    display: block;
    margin-bottom: 30px;
  }
`;

const BrandsImage = styled(Image)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
`;

const BrandsContent = styled('div')`
  color: #000;
  padding: 0 20px;
  ${({ theme }) => theme.below.sm} {
    padding: 0 3px;
  }
  h2 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  p {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
  div {
    margin-top: 20px;
    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      ${({ theme }) => theme.below.sm} {
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-track,
        ::-webkit-scrollbar-thumb {
          background-color: transparent;
          display: none;
        }
        ::-webkit-scrollbar {
          display: none;
        }
      }
      img {
        max-width: 75px;
        margin: 10px;
        ${({ theme }) => theme.below.sm} {
          max-width: 100px;
          margin: 5px;
        }
      }
    }
  }
`;

const StartPageBrands = ({ heading, text, catId }) => {
  const { data } = useQuery(selectedBrandsQuery, {
    variables: {
      catId: catId
    }
  });
  if (!data) return null;
  return (
    <Brands>
      <BrandsContent>
        <Above breakpoint="lg">
          <>
            <h2>{heading}</h2>
            <p>{text}</p>
          </>
        </Above>

        <div dangerouslySetInnerHTML={{ __html: data.category.content }} />
      </BrandsContent>
    </Brands>
  );
};

export default StartPageBrands;
