import { useMemo } from 'react';
export const useFauxPricing = (item = null) => {
  const calculateDiscount = (product = item) => {
    if (!product) return null;
    const campaigns = product?.campaigns
      ? product.campaigns.filter((c) => {
        const number = c?.name.match(/^\d+|\d+\b|\d+(?=\w)/g);
        if (number) {
          return c;
        }
      })
      : null;
    if (!campaigns || campaigns.length === 0) {
      return { value: 0, percent: false };
    }
    const numbers = campaigns[0].name
      .match(/^\d+|\d+\b|\d+(?=\w)/g)
      .map(function (v) {
        return +v;
      });
    if (numbers.length === 0) {
      return 0;
    }
    let percent = false;
    if (campaigns[0].name.indexOf('%') > -1) {
      percent = true;
    }
    return { value: numbers[0], percent: percent };
  };

  const calculatePrice = (product = item) => {
    const fauxDiscount = calculateDiscount(product);
    if (fauxDiscount.percent) {
      const percentage = (100 - fauxDiscount.value) / 100;
      const fauxPrice = {
        exVat: Math.round(product?.previousPrice?.exVat * percentage * 0.75),
        incVat: Math.round(product?.previousPrice?.incVat * percentage),
        vat: Math.round(product?.previousPrice?.vat * percentage * 0.25),
      };
      return fauxPrice;
    } else {
      const fauxPrice = {
        exVat: product?.previousPrice?.exVat - fauxDiscount?.value * 0.75,
        incVat: product?.previousPrice?.incVat - fauxDiscount?.value,
        vat: product?.previousPrice?.vat - fauxDiscount?.value * 0.25,
      };
      return fauxPrice;
    }
  };

  const fauxPrice = useMemo(() => {
    if (item === null) return null;
    return calculatePrice();
  }, [item]);
  return { fauxPrice, calculateDiscount, calculatePrice };
};
