import React from 'react';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

const List = styled('div')`
  ${props =>
    props.left
      ? css`
          margin-right: 8px;
          margin-left: 0px;
          li.top-nav-list-item:first-of-type {
            padding-left: 0px;
            padding-right: 8px;
          }
        `
        ? props.center
        : css``
      : css`
          justify-content: flex-end;
          li.top-nav-list-item:last-of-type {
            padding-left: 0.5rem;
            padding-right: 0px;
          }
        `};
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  z-index: 4;
  button {
    background: none;
    color: inherit;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
    transition: all ease 0.3s;
    :hover {
      color: ${({ theme }) => theme.colors.grey};
    }
  }

  ${theme('below.md')} {
    flex: 1;
    justify-content: flex-start;
  }
`;

export default ({ children, ...rest }) => (
  <List {...rest}>
    {React.Children.map(children, item => item && <span>{item}</span>)}
  </List>
);
