import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';
import getCategoriesByLevel from './__util__/getCategoriesByLevel';
import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
import hasChildCategories from './__util__/hasChildCategories';
import hasChildCategory from './__util__/hasChildCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@jetshop/ui/utils/theme';

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

export const CloseButton = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  padding: 1rem;
  svg {
    display: block;
    width: 20px !important;
    height: 20px;
  }
`;

const Wrapper = styled('div')`
  &.toplvl {
    padding: 0 1rem;
  }
  a {
    border-top: 1px solid #d2d2d2;
    display: block;
    padding: 0.5rem 0;
    position: relative;
    color: black;
    text-decoration: none;
    font-family: ${theme('fonts.secondary')};
    font-weight: bold;
    text-transform: uppercase;

    &[href$='rea'] {
      color: #ff0000 !important;
    }
    &[href$='julklappstips'] {
      color: #ff0000 !important;
    }

    &.allbtn {
      font-weight: 900;
    }
    svg {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:last-child a {
    border-bottom: 1px solid #d2d2d2;
  }
`;

const SubcategoryWrapper = styled('div')`
  padding: 1rem;
  background: white;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  transition: transform 0.5s;
  overflow: hidden;
  &.open {
    transform: translateX(0);
    opacity: 1;
    max-height: 9999px;
  }
`;

const SubNavHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 50px;
  font-family: ${theme('fonts.secondary')};
  text-transform: uppercase;
  .close-btn {
    padding-right: 0;
  }
`;

const BackButton = styled('button')`
  background: none;
  padding: 0 1rem 0 0;
  &:focus {
    outline: none;
  }
  font-size: 1rem;
  text-transform: uppercase;
`;

const CategoryTitle = styled('span')`
  font-size: 1.5rem;
  font-weight: 900;
  font-family: ${theme('fonts.secondary')};
  text-transform: uppercase;
`;

const MobileCategories = ({
  categories,
  closeMenu,
  parentCategory = null,
  menuWrapper,
  setLocked,
  openCat,
  setOpenCat
}) => {
  const thisSubMenu = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const categoryLevel = parentCategory
    ? [parentCategory]
    : getCategoriesByLevel(categories, 1);

  useEffect(
    v => {
      if (thisSubMenu.current && thisSubMenu.current.scrollHeight) {
        setLocked(
          thisSubMenu.current.scrollHeight < menuWrapper.current.scrollHeight
        );
      } else {
        setLocked(false);
      }
      if (openCat === null) {
        setOpenIndex(null);
      }
    },
    [openCat, menuWrapper, setLocked]
  );

  return (
    <>
      {categoryLevel.map((category, i) => (
        <Wrapper
          className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
          key={category.id}
        >
          <CategoryLink
            className={`${!parentCategory ? 'toplvl' : 'sublvl'}`}
            onClick={e => {
              // If this category has child categories, we want to open them up with `handleClick`
              // Otherwise, we want to follow the link and `closeMenu`
              if (hasChildCategories(categories, category)) {
                handleClickAndPreventDefault(
                  e,
                  () => {
                    setOpenCat(category.id);
                    setOpenIndex(i);
                    menuWrapper.current.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  },
                  i
                );
              } else {
                closeMenu();
              }
            }}
            category={category}
          >
            {category.name}
            {hasChildCategories(categories, category) && (
              <FontAwesomeIcon icon={['fal', 'angle-right']} />
            )}
          </CategoryLink>

          <SubcategoryWrapper
            className={openIndex === i ? 'open' : null}
            innerRef={openCat === category.id ? thisSubMenu : null}
          >
            <SubNavHeader>
              <BackButton
                onClick={() => {
                  setOpenCat(category.parentId);
                  setOpenIndex(category.parentId);
                  setLocked(false);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'arrow-left']} />
              </BackButton>
              <CategoryTitle>{category.name}</CategoryTitle>
              <CloseButton
                className="close-btn"
                onClick={() => {
                  closeMenu();
                  setOpenCat(null);
                }}
              >
                <FontAwesomeIcon icon={['fal', 'times']} />
              </CloseButton>
            </SubNavHeader>
            <Wrapper>
              <CategoryLink
                onClick={() => {
                  closeMenu();
                  setOpenCat(null);
                }}
                className={'allbtn sublvl'}
                category={category}
              >
                {t('See all in')} {category.name}
              </CategoryLink>
            </Wrapper>
            <SubCategories
              setLocked={setLocked}
              menuWrapper={menuWrapper}
              categories={categories}
              category={category}
              closeMenu={closeMenu}
              setOpenCat={setOpenCat}
              openCat={openCat}
            />
          </SubcategoryWrapper>
        </Wrapper>
      ))}
    </>
  );
};

const SubCategories = ({
  categories,
  category,
  closeMenu,
  menuWrapper,
  setLocked,
  setOpenCat,
  openCat
}) => {
  return getCategoriesUnderParent(categories, category.id).map(
    (childCat, i) => {
      return hasChildCategories(categories, childCat) ? (
        <MobileCategories
          setLocked={setLocked}
          menuWrapper={menuWrapper}
          key={i}
          categories={categories}
          closeMenu={closeMenu}
          parentCategory={childCat}
          setOpenCat={setOpenCat}
          openCat={openCat}
        />
      ) : (
        <>
          {childCat.name === 'Menybild' ? null : (
            <CategoryLink
              className="sublvl"
              key={i}
              onClick={() => {
                closeMenu();
                setOpenCat(null);
              }}
              category={childCat}
            >
              {childCat.name}
            </CategoryLink>
          )}
        </>
      );
    }
  );
};

export default MobileCategories;
