import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useEffect, useContext } from 'react';
import styled from 'react-emotion';
import { useScript } from '../../hooks/useScript';
import { API_KEY_SKI, API_KEY_MTB } from './LipscoreGlobalThingis';
import { theme } from '../Theme';

const ProductRatingWrapper = styled('div')`
  margin-top: 6px;
  margin-bottom: 12px;

  #lipscore-rating {
    display: flex;
    cursor: pointer;

    .lipscore-compact-rating-votes {
    }
  }

  .lipscore-rating-slider {
    display: flex;
    align-items: center;
    .lipscore-rating-slider-stars-wrapper {
      .lipscore-rating-star-wrapper {
        .lipscore-rating-star {
          /* fill: black; */
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .lipscore-compact-rating-wrapper {
    display: flex;
    align-items: center;

    .lipscore-compact-rating-value {
      font-family: ${theme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.055em;
      margin-left: 3px;
    }

    .lipscore-compact-rating-votes {
      opacity: 0.7;
      font-family: ${theme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.055em;
    }
  }

  .lipscore-rating-review-link {
    color: black;
    margin-left: 5px;
    display: none;
  }

  //********* END Styling for detail view *************

  //********* Styling for Card view *************
  .lipscore-rating-small {
    .lipscore-rating-star {
      fill: black;
      height: 12px;
      width: 12px;
    }
  }
  //********* END Styling for Card view *************
`;

export const LipscoreProductSmallRating = ({
  product,
  onCard,
  handleClick,
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  const language = selectedChannel?.country?.code?.toLowerCase();
  const currency = selectedChannel?.currency?.name;
  const brand = product?.subName;
  const path = product?.primaryRoute?.path;
  const [loaded, error] = useScript(
    '//static.lipscore.com/assets/' + language + '/lipscore-v1.js'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loaded) {
        if (window.lipscore) {
          if (!window.lipscore.isInitialized()) {
            window.lipscoreInit = () => {
              lipscore.init({
                apiKey: selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB,
              });
            };
            window.lipscore.apiKey =
              selectedChannel?.id === 1 ? API_KEY_SKI : API_KEY_MTB;
          }
          window.lipscore.initWidgets();
        }
      }
    }
  }, [loaded]);

  if (!loaded || error) return null;

  return (
    loaded && (
      <ProductRatingWrapper>
        {onCard ? (
          <div
            className="lipscore-rating-small"
            ls-product-name={product?.name}
            ls-brand={brand}
            ls-product-id={product?.id}
            ls-product-url={selectedChannel?.url + path}
            ls-description={product?.shortDescription}
            ls-price={product?.price?.incVat}
            ls-price-currency={currency}
          />
        ) : (
          <div
            id="lipscore-rating"
            ls-product-name={product?.name}
            ls-brand={brand}
            ls-product-id={product?.id}
            ls-product-url={selectedChannel?.url + path}
            ls-description={product?.shortDescription}
            ls-price={product?.price?.incVat}
            ls-price-currency={currency}
            onClick={() => handleClick('3')}
          />
        )}
      </ProductRatingWrapper>
    )
  );
};
