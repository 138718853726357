import React from 'react';
import styled from 'react-emotion';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    height: 0.9rem;
    width: 0.9rem;
    border: 0;
    border-radius: 50%;
    font-size: 10px;
    line-height: 0.9rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue};
    overflow: hidden;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <span>{text}</span>
    </Wrapper>
  );
};

export default Badge;
