import React from 'react';
import styled, { css } from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FieldErrorWrapper = styled('div')`
  ${({ theme }) => theme.below.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: transparent;
  height: 2.5rem;
  display: flex;
  flex: 0 1 20rem;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 2rem;
  }
  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    border: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
  }

  input:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    transition: all 0.3s linear;
  }

  input::placeholder {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: normal;
    opacity: 1;
  }

  button {
    color: ${({ theme }) => theme.colors.primary};
    background: none;
    border: 0;
    outline: none;
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    height: 100%;
    cursor: pointer;
  }

  svg {
    font-size: 22px;
    vertical-align: middle;
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.alert};
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <FieldErrorWrapper>
              {this.state.error ? (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('You have already subscribed to the newsletter!')
                    : t(
                        'Something went wrong. Please check your email and try again.'
                      )}
                </Error>
              ) : (
                <label htmlFor="newsletter">
                  <h2>{t('Sign up for newsletter')}</h2>
                </label>
              )}
              <Wrapper>
                {/* Show the completion success message once signed up, otherwise show the form */}
                {this.state.completed ? (
                  <p>{t('You are now subscribed')}</p>
                ) : (
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          name="newsletter"
                          type="email"
                          placeholder={t('Enter email address')}
                          onChange={this.updateEmail}
                          className={css`
                            padding-right: ${result.loading ? '8rem' : '4rem'};
                          `}
                        />
                      )}
                    </Intl>
                    <button>
                      {/* Display 'Submitting…' while the form is posting */}
                      <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
                    </button>
                  </form>
                )}
              </Wrapper>
            </FieldErrorWrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
