import React from 'react';
import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';

const TrendButtonStyle = ({ secondary, theme }) => css`
  background-color: ${theme.colors.primary};
  border-color: ${theme.colors.primary};
  color: white;
  font-size: 16px;
  font-weight: 900;
  padding: 10px 50px;
  border-radius: 0;
  font-weight: bold;
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  ${theme.above.md} {
    max-width: 100%;
  }

  :disabled {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  ${secondary &&
    css`
      background-color: ${theme.colors.primary};
      border: 0;
      color: ${theme.colors.color};

      circle.path {
        stroke: black;
      }
    `};
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
`;

export const TrendLink = styled(Link, {
  shouldForwardProp: prop => ['secondary'].indexOf(prop) === -1
})`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  transition: all, 0.2s ease;
  &:hover {
    background-color: ${theme('colors.primaryHover')}!important;
    border-color: ${theme('colors.primaryHover')}!important;
  }
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button {...props}>
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
