import Head from '@jetshop/core/components/Head';
import React from 'react';
import StartPageRow from './StartPageRow';
const StartPageRenderer = ({
  result,
  ProductGrid,
  startPageComponents,
  LoadingPage
}) => {
  const data = result.data;
  if (!(data && data.startPage)) {
    return LoadingPage ? <LoadingPage /> : null;
  }
  if (data && data.startPage) {
    const items = data.startPage.items;
    if (!items || items.length < 1) return null;
    const numberOfRows = items[items.length - 1].row;
    const startPageGrid = [...Array(numberOfRows)].map((_, index) => {
      return (
        <StartPageRow
          ProductGrid={ProductGrid}
          result={result}
          startPageComponents={startPageComponents}
          index={index}
          items={items}
          key={index}
        />
      );
    });
    return (
      <React.Fragment>
        <Head data={data.startPage.head} />
        {startPageGrid}
      </React.Fragment>
    );
  }
};
export default StartPageRenderer;
