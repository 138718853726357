import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Price } from '../Price';

const common = css`
  line-height: 1.5;
  font-size: 0.85em;
  text-align: center;
  color: #000;
  margin-bottom: 1em;
`;

const UntilLimit = styled('div')`
  ${common};
  ${Price.Wrapper} {
    display: inline;
    > * {
      display: inline;
    }
  }
`;

const Reached = styled('div')`
  font-size: 14px;
  p {
    font-weight: bold;
  }
  ${common};
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit)
    return (
      <UntilLimit key="until-limit">
        {t.rich(`Spend {price} more to qualify for free shipping!`, {
          price: <Price key="price" price={untilLimit} />
        })}
      </UntilLimit>
    );

  return (
    <Reached {...rest}>
      <p>{t('Delivery')}</p>
      <label>{t('Free for your order')}</label>
    </Reached>
  );
}

export default FreeShipping;
