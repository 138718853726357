import { Link } from 'react-router-dom';
import CategoryLink from '@jetshop/ui/CategoryLink';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';

import { Query } from 'react-apollo';
import FooterQuery from './FooterQuery.gql';

const PoweredByWrapper = styled('div')`
  padding-top: 8px;
  p {
    text-align: center;
    margin-top: 0.4rem;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0;
    ${({ theme }) => theme.below.md} {
      padding-bottom: 20px;
    }
  }
`;

const Wrapper = styled('section')`
  text-align: left;
  font-size: 16px;
  background: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.above.md} {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.primary};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 20px;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    padding-bottom: 17px;
    line-height: 20px;
  }

  a {
    display: block;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.3s linear;
    }
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  justify-content: flex-start;
  flex-direction: row;

  > section {
    margin: 0 2rem 0 0;
    flex: 0 1 30%;
  }

  h2 {
    text-transform: inherit;
    font-size: 16px;
  }

  ${({ theme }) => theme.below.md} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 21px 15px 21px;

    > section {
      margin: 0;
      flex: 0 1 45%;
    }
  }
`;

const NewsletterWrapper = styled('div')`
  text-align: left;
  margin-left: auto;
  width: 35%;

  ${({ theme }) => theme.below.md} {
    width: 100%;
    max-width: 350px;
    margin: 0;
  }
`;

const FooterColumn = ({ id }) => (
  <Query query={FooterQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading) return null;
      if (error) return `Error!: ${error}`;
      return (
        <>
          {data.category && data.category.subcategories && (
            <section>
              <h2>{data.category.mainHeader}</h2>
              {data.category && data.category.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.category.content
                  }}
                />
              ) : (
                <ul>
                  {data.category.subcategories.map((subcat, i) => (
                    <li key={i}>
                      <CategoryLink category={subcat}>
                        {subcat.name}
                      </CategoryLink>
                    </li>
                  ))}
                  <li>
                    <Link to="/service-1">Service</Link>
                  </li>
                  <li>
                    <Link to="/kunskapsbank">Kunskapsbank</Link>
                  </li>
                </ul>
              )}
            </section>
          )}
        </>
      );
    }}
  </Query>
);

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <Wrapper>
      <WrapFooterNav>
        {selectedChannel.id === 1 ? (
          <>
            <FooterColumn id={453} />
            <FooterColumn id={437} />
          </>
        ) : (
          <>
            <FooterColumn id={471} />
            <FooterColumn id={372} />
          </>
        )}
        <NewsletterWrapper>
          <NewsletterField />
          <SocialLinks />
        </NewsletterWrapper>
      </WrapFooterNav>
      <PoweredByWrapper>
      </PoweredByWrapper>
    </Wrapper>
  );
};

export default Footer;
