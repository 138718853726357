import React from 'react';
import Slider from 'react-slick';
import styled from 'react-emotion';
import 'slick-carousel/slick/slick.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SliderWrapper = styled('div')`
  padding: 0 1rem;
  position: relative;
  margin-bottom: 1rem;

  .boxImage.boxImage {
    padding-bottom: 50% !important;
  }
  .boxContent {
    position: relative;
    margin-top: 20px;
    padding-bottom: 10px;
    h3 {
      font-size: 1rem;
    }
  }

  .articleWrapper {
    a {
      margin-bottom: 0;
    }
    .articleContent {
      padding: 0;
    }
  }

  &.articleRow {
    button.slick-arrow {
      ${({ theme }) => theme.below.md} {
        top: 205px;
      }

      ${({ theme }) => theme.below.sm} {
        top: 90px;
      }
    }
  }
`;
const Arrow = styled('button')`
  background: black;
  border-radius: 100%;
  border: 0;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 25%;
  color: white;
  z-index: 1;

  ${({ theme }) => theme.below.md} {
    top: 170px;
  }

  ${({ theme }) => theme.below.sm} {
    top: 70px;
  }

  &.slick-next {
    transform: rotate(0deg);
    right: -10px;
    left: auto;
  }
  &.slick-prev {
    transform: rotate(180deg);
    right: auto;
    left: -10px;
  }
  &:focus {
    outline: none;
  }

  svg {
    width: 40px;
    height: auto;
  }
`;

const Dots = styled('ul')`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: 5px;
`;
const Dot = styled('button')`
  background: black;
  border: 0;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin: 0 5px;
  &:focus {
    outline: none;
  }

  .slick-active & {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
    </Arrow>
  );
};

const StartPageSlider = ({ rowItems, startPageComponents, className }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />
  };
  return (
    <SliderWrapper className={className}>
      <Slider {...settings}>
        {rowItems.map((item, index) => {
          var content = JSON.parse(item.item.content);
          var Component = startPageComponents[item.item.name];
          return Component ? (
            <Component
              {...Object.assign({ key: index }, content, {
                imageSrc: item.item.image && item.item.image.url,
                linkUrl: item.item.linkUrl
              })}
            />
          ) : null;
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default StartPageSlider;
