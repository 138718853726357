import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { useContext } from 'react';
import styled from 'react-emotion';
import { TrendLink } from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import FreeShipping from './FreeShipping';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import HelloRetailRecommendations from '../HelloRetail';

const Flyout = styled('div')`
  background: white;
  color: ${theme('colors.black')};
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  overflow-y: scroll;
  ${({ theme }) => theme.below.md} {
    max-width: 90% !important;
    height: 100%;
  }
  h2 {
    font-size: 26px;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
  }
  ${({ theme }) => theme.above.md} {
    position: relative;
    z-index: 999;
    right: 0;
    top: 0;
    height: calc(100% - 109.5px);
  }
  &.top {
    ${({ theme }) => theme.above.md} {
      height: calc(100% - 181px);
    }
  }
`;

const LightText = styled('span')`
  color: ${theme('colors.mediumgrey')};
  font-size: 14px;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
  text-transform: uppercase;
`;

const Header = styled('header')`
  text-align: center;
  h2 {
    font-weight: 600;
    font-family: ${theme('fontFamilies.heavy')};
  }
  ${LightText} {
    display: block;
  }
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  margin: 0 1rem;
  h2 {
    font-size: 14px !important;
  }

  img {
    padding: 5px;
  }
`;

const Summary = styled('section')`
  background: #fff;
  width: calc(100% - 2rem);
  margin: auto;
  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    padding: 0rem 1rem 0 1rem;
    font-size: 14px;
    > div {
      margin-top: 14px;
    }
    h2 {
      margin: 10px 0 5px 0 !important;
    }
  }

  .cart-total {
    padding: 1em 1rem 0 1rem;

    .shipping,
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 1.25rem;
      font-weight: 600;
      font-family: ${theme('fontFamilies.heavy')};
      color: ${theme('colors.black')};
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const FlyoutContainer = styled('div')`
  &.top {
    & > div {
      max-width: 90% !important;
      ${theme('above.md')} {
        top: 181px;
      }
    }
  }
  & > div {
    max-width: 90% !important;
    ${theme('above.md')} {
      top: 109.5px;
    }
  }
`;

const CartTop = styled('div')``;
const CartBottom = styled('div')``;

const VatDetails = styled('div')`
  color: ${theme('colors.mediumgrey')};
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ContinueShopping = styled('button')`
  background: ${theme('colors.lightgrey')};
  color: ${theme('colors.black')};
  font-size: 16px;
  font-weight: bold;
  margin: 0.5rem 0 1rem 0;
  padding: 0.5rem;
  text-align: center;
  transition: all ease 0.3s;
  width: 100%;
  :hover {
    background: ${theme('colors.mediumgrey')};
  }
`;

const Checkout = styled(TrendLink)`
  width: 100%;
  display: flex;
`.withComponent('a');

const RecommendedWrapper = styled('div')`
  .hello-retail-recommendations {
    padding: 0;
    > div h2 {
      margin-top: 1rem;
      marin-bottom: 0;
    }
  }
  padding: 0 !important;
  & > div {
    margin-top: 0;

    .hello-retail-recommendations {
      padding-top: 0;
      padding-bottom: 0;
      & > div h2 {
        margin-top: 1.5rem;
        font-size: 0.8em;
      }

      .product-card {
        padding-bottom: 0;

        .product-card-detail {
          h3 {
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const CartFlyoutView = ({ result, modal, isScrolled, ...rest }) => {
  const items = get(result, 'data.cart.items', []);
  const itemCount = get(result, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');
  const discounts = get(result, 'data.cart.aggregatedDiscounts', []);
  const track = useTracker();
  const { selectedChannel } = useContext(ChannelContext);

  const helloRetailRecommendationsId1 =
    selectedChannel.id === 1
      ? 'k5fbbc43aa5a4ee1229620aca'
      : 'k5fbbc33ba5a4ee1229617f04';

  const VAT = new Intl.NumberFormat(selectedChannel.defaultLanguage.culture, {
    style: 'currency',
    currency: selectedChannel.currency.name,
  }).format(result.data.cart.productTotal.vat);

  const itemsContainsBike = () => {
    return (
      items.filter((item) => item.product.primaryRoute.id.includes('cyklar'))
        .length > 0
    );
  };

  if (items.length === 0) {
    return (
      <Flyout {...rest} className={isScrolled ? 'scrolled' : 'top'}>
        <h2>{t('Cart')}</h2>
        <div
          css={{
            height: '100%',
            textAlign: 'center',
            padding: '0em 1rem',
            fontSize: '14px',
          }}
        >
          {t('No items in cart.')}
        </div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest} className={isScrolled ? 'scrolled' : 'top'}>
      <CartTop>
        <Header>
          <h2>{t('Cart')}</h2>
          <ItemCount count={itemCount} />
        </Header>

        <CartItems>
          {items.map((item) => (
            <CartItem item={item} key={item.id} />
          ))}
        </CartItems>
        <RecommendedWrapper>
          <HelloRetailRecommendations
            recommendationsList={[
              {
                title: 'Recommended products',
                recommendationId: helloRetailRecommendationsId1,
                slider: 'flyout',
                cart: result.data.cart,
              },
            ]}
          />
        </RecommendedWrapper>
      </CartTop>
      <CartBottom>
        <Summary>
          {discounts.length > 0 && (
            <div className="discounts">
              <h2>{t('Cart total')}</h2>
              <Flex>
                <label>{t('Order value')}</label>
                <Price price={result.data.cart.productPreviousTotal} />
              </Flex>
              <h2 css={{ marginTop: '1em' }}>{t('Discounts')}</h2>
              {discounts.map((discount) => {
                return (
                  <Flex key={discount.name} css={{ marginTop: '1em' }}>
                    <label>{discount.name}</label>
                    <Price price={discount.value} css={{ display: 'inline' }} />
                  </Flex>
                );
              })}
            </div>
          )}

          <div className="cart-total">
            {itemsContainsBike() ? null : (
              <FreeShipping
                className="shipping"
                cartTotal={result.data.cart.productTotal}
              />
            )}
            <div className="total">
              <label>{t('Total')}</label>
              <Price price={result.data.cart.productTotal} />
            </div>
            <VatDetails>
              <div>{t('of which VAT')}:</div>
              <div>{VAT}</div>
            </VatDetails>
          </div>
        </Summary>

        <Summary>
          {checkoutUrl && (
            <Checkout
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckoutEvent({ cart: result.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
          <ContinueShopping onClick={modal.hideTarget}>
            {t('Continue shopping')}
          </ContinueShopping>
        </Summary>
      </CartBottom>
    </Flyout>
  );
};

const CartFlyout = ({ props, isScrolled }) => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {(result) =>
      result.data && result.data.cart ? (
        <FlyoutContainer className={isScrolled ? 'scrolled' : 'top'}>
          <DrawerTarget id="cart-drawer">
            {(drawer) => (
              <Drawer isOpen={drawer.isOpen} right>
                <CartFlyoutView
                  modal={drawer}
                  result={result}
                  {...props}
                  isScrolled={isScrolled}
                />
              </Drawer>
            )}
          </DrawerTarget>
        </FlyoutContainer>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
