import React from 'react';
import styled from 'react-emotion';
import { Below } from '@jetshop/ui/Breakpoints';

import StartPageItems from './StartPageItems';
import MaxWidth from '../../Layout/MaxWidth';
import StartPageSlider from '../Content/StartPageSlider';

const Row = styled(MaxWidth)`
  &.heroRow,
  &.bannerRow {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  &.pushRow {
    ${({ theme }) => theme.below.md} {
      padding: 0 1rem;
    }
  }
  &.boxRow,
  &.articleRow,
  &.categoryboxRow,
  &.infoRow {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 1rem auto;
    ${({ theme }) => theme.below.md} {
      margin: 0;
    }
    section {
      width: calc(${props => 100 / props.numItems}% - 1rem);
      ${({ theme }) => theme.below.md} {
        width: calc(${props => 200 / props.numItems}% - 0.5rem);
        margin-bottom: 1rem;
      }
    }
    ${({ theme }) => theme.below.md} {
      display: flex;
      padding: 0 1rem;
    }
  }
  &.infoRow {
    flex-wrap: no-wrap;
  }

  &.categoryboxRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;

    ${({ theme }) => theme.below.md} {
      flex-wrap: wrap;
    }

    &.items-3 {
      ${({ theme }) => theme.below.md} {
        flex-wrap: wrap;
        section {
          width: 48%;
          &:last-of-type {
            width: 100%;
          }
        }
      }
    }
  }

  &.articleRow {
    ${({ theme }) => theme.below.md} {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }

      > section {
        width: 90%;
        margin-right: 1rem;
        flex: none;
      }
    }
  }
`;

const isSlider = rowItems => {
  if (
    rowItems[0]?.item?.name?.toLowerCase() === 'article' ||
    (rowItems[0]?.item?.name?.toLowerCase() === 'box' &&
      rowItems?.length !== 4 &&
      rowItems?.length > 1)
  ) {
    return true;
  }
  return false;
};

const StartPageRow = ({
  ProductGrid,
  startPageComponents,
  LoadingPage,
  index,
  items
}) => {
  const rowNumber = index + 1;
  const rowItems = items.filter(item => item.row === rowNumber);
  if (rowItems[0]) {
    return (
      <Below breakpoint="md">
        {matches => (
          <>
            {isSlider(rowItems) && matches ? (
              <StartPageSlider
                rowItems={rowItems}
                startPageComponents={startPageComponents}
                className={rowItems[0]?.item?.name?.toLowerCase() + 'Row'}
              />
            ) : (
              <Row
                numItems={rowItems.length}
                className={
                  rowItems[0]?.item?.name?.toLowerCase() +
                  'Row ' +
                  'items-' +
                  rowItems.length
                }
              >
                <StartPageItems
                  rowItems={rowItems}
                  startPageComponents={startPageComponents}
                  LoadingPage={LoadingPage}
                  ProductGrid={ProductGrid}
                  index={index}
                />
              </Row>
            )}
          </>
        )}
      </Below>
    );
  } else return null;
};
export default StartPageRow;
