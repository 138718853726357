import t from '@jetshop/intl';
import ChannelSelector, {
  LangCurrWrapper,
  SelectorBody,
  Title
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import {
  CancelButton,
  CommitButton
} from '@jetshop/ui/ChannelSelector/ChannelSelectorButtons';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import CheckboxGroup from './CheckboxGroup';
import React, { useState } from 'react';
import styled from 'react-emotion';
import flattenCountries from './flattenCountries';

const Country = styled('div')`
  height: 42px;
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? '#ebebeb' : 'transparent')};
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: ${({ isActive }) => (isActive ? '#000' : '#4f4f4f')};
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  :hover {
    background-color: ${({ isActive }) => !isActive && '#f6f6f6'};
  }
`;
const StyledCommitButton = styled(CommitButton)`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  font-weight: bold;
`;
const StyledCancelButton = styled(CancelButton)`
  background: #ddd;
  color: black;
  font-weight: bold;
`;

const CountryFlag = styled('img')`
  height: 12px;
  width: 16px;
  margin-right: 15px;
`;

const ChecboxGroupContainer = styled('div')`
  & > div {
    position: relative;
    //padding-left: 80px;
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      & ~ label {
        margin-top: 0.6rem;
        color: #000;
      }
      & + label {
        margin-top: 0;
      }
    }
  }
  input ~ span {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    svg {
      height: 8px;
    }
  }
  label input:checked ~ span {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTitle = styled(Title)`
  color: #000;
  text-align: center;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 18px;
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel
}) => {
  const [countries] = useState(flattenCountries(channels));
  const Comp = type;

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => (
        <Comp css={type === MiniSelector && { width: '16rem' }}>
          {/* <SelectorBody>
            <StyledTitle>{t('Select country')}</StyledTitle>
            {countries.map(
              ({ name, code, channel }) =>
                channel.id ===selectedChannel.id && (
                  <Country
                    key={channel.id + name}
                    isActive={
                      channel.id === selectedChannel.id &&
                      name === selectedChannel.country.name
                    }
                    onClick={() => onSelect(channel.id, null, null, code)}
                  >
                    <CountryFlag
                      src={`https://countryflags.jetshop.io/${code}/flat/32.png`}
                      alt="Country Flag"
                    />
                    {name}
                  </Country>
                )
            )}
          </SelectorBody> */}
          <LangCurrWrapper style={{ margin: '0' }}>
            <ChecboxGroupContainer>
              <CheckboxGroup
                selectedItem={selectedChannel.language.name}
                items={languageItems}
                handleChange={onLanguageSelect}
                groupLabel={t('Language')}
              />
              <CheckboxGroup
                title="Currency"
                selectedItem={selectedChannel.currency.name}
                items={currencyItems}
                handleChange={onCurrencySelect}
                groupLabel={t('Currency')}
              />
            </ChecboxGroupContainer>
          </LangCurrWrapper>
          {hasChanged ? (
            <StyledCommitButton
              onClick={() => {
                updateChannel(selectedChannel);
                hideTarget();
              }}
              text={t('Update')}
            />
          ) : (
            <StyledCancelButton
              text={t('Close')}
              onClick={() => hideTarget()}
            />
          )}
        </Comp>
      )}
    />
  );
};

export default Selector;
