import React, { useEffect, useState, useContext } from 'react';
import styled from 'react-emotion';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as InstagramLogo } from '../../svg/InstagramLogo.svg';

const InstagramWrapper = styled('div')`
  width: 100%;
  max-width: 80rem;
  margin: auto;
  padding: 0 2rem;
  margin-top: 2rem;
  ${theme('below.md')} {
    padding: 0 20px;
  }
`;

const InstagramContentWrapper = styled('div')`
  justify-content: space-around;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  ${theme('below.md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const InstagramItem = styled('div')`
  width: 100%;
  display: block;
  padding: 0 5px;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  position: relative;

  ${theme('below.md')} {
    &:nth-of-type(n + 5) {
      display: none;
    }
  }
`;

const InstagramImageWrapper = styled('div')`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
`;

const InstagramHeader = styled('div')`
  text-align: center;
  grid-column: 2 / 4;
  grid-row: 1;
  align-items: center;
  display: flex;
  justify-content: center;

  ${theme('below.md')} {
    grid-column: 2 / 3;
    background: black;
  }

  h2 {
    ${theme('below.md')} {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  svg {
    width: 32px;
    height: 32px;
    margin-bottom: 5px;
    ${theme('below.md')} {
      margin: 0;
      g,
      use {
        fill: white;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
    ${theme('below.md')} {
      color: white;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  span.fake-button {
    background: ${theme('colors.primary')};
    font-family: ${theme('fonts.primary')};
    color: white;
    padding: 5px 25px;
    display: inline-block;
    font-size: 1rem;
    text-transform: none;
    ${theme('below.md')} {
      display: none;
    }
  }
  span.insta-name {
    display: block;
    color: black;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 20px;
    ${theme('below.md')} {
      color: white;
      font-size: 1.2rem;
      margin: 0;
    }
  }
`;

const channelInfo = {
  SKI: {
    accountID: '17841400832144760',
    accessToken:
      'EAAJYiZBFXOaYBAKq7ICKUC4mnw8ZCZAoF5YZC7StYLXyqZCKTSdOPY2e66kS1uUZCm9BqKJ1CCwOkaZBFrE3jA1Rh5jG9SUKTOdYQn4ighgdoimmR771qQD0meZA3McbKEgU7rTWrp9z6k43ZBa5R5MKAuTZBceZBZCx7GoSCIZBExf3b4gZDZD',
    link: 'https://www.instagram.com/skistore/',
    name: '@Skistore'
  },
  MTB: {
    accountID: '17841400223505530',
    accessToken:
      'EAAJYiZBFXOaYBAFWUEGa6JK3TSfYLcK4sRtzZC3QOIw8j5A819b0e5cB1y7NqvdSZBATB3giGygFwSSuIaZAU6WRJEG1jp8aB8pKaogxeLThQx38ipgG24GLZA8OmMxd6iZCCt7fYJpABfvkDAy1MFQ9XYxTf2SwytjwQ8mzLnkgZDZD',
    link: 'https://www.instagram.com/mtbstore',
    name: '@Mtbstore'
  }
};

const Instagram = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const channel = channelInfo[selectedChannel.name];
  const accountId = channel.accountID;
  const accessToken = channel.accessToken;
  const link = channel.link;
  const name = channel.name;

  const [images, setImages] = useState([]);
  var numImages = images !== undefined ? images.length : 0;
  useEffect(() => {
    fetch(
      `https://graph.facebook.com/${accountId}/media?limit=6&fields=thumbnail_url,media_url,permalink&access_token=${accessToken}`
    )
      .then(response => response.json())
      .then(json => {
        setImages(json.data);
      });
  }, [numImages]);
  return (
    <InstagramWrapper>
      {images && (
        <>
          <InstagramContentWrapper>
            <InstagramHeader>
              <h2>
                <a href={link} target="_blank">
                  <InstagramLogo />
                  <span className="insta-name">{name}</span>
                  <span className="fake-button">Följ oss!</span>
                </a>
              </h2>
            </InstagramHeader>
            {images.map(image => (
              <InstagramItem key={image.id}>
                <a
                  href={image.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramImageWrapper
                    style={{
                      backgroundImage: `url(${
                        image[
                          image.thumbnail_url !== undefined
                            ? 'thumbnail_url'
                            : 'media_url'
                        ]
                      })`
                    }}
                  />
                </a>
              </InstagramItem>
            ))}
          </InstagramContentWrapper>
        </>
      )}
    </InstagramWrapper>
  );
};
export default Instagram;
