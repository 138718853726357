import React from 'react';
import styled from 'react-emotion';
import MaxWidth from '../../Layout/MaxWidth';
import { ProductGrid } from '../../CategoryPage/ProductGrid';

const Container = styled('div')`
  margin: 1rem auto;
  width: 100%;
  .product-card {
    ${({ theme }) => theme.above.lg} {
      width: 25% !important;
    }
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
const StyledProductGrid = styled(ProductGrid)``;

const StartPageProductGrid = props => (
  <Container>
    <StyledProductGrid
      {...props}
      imageSizes={[1 / 2, 1 / 2, 1 / 4]}
      list={'Start page - Selected products'}
      sliderMobile={true}
    />
  </Container>
);

export default StartPageProductGrid;
