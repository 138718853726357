import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import helloRetailQuery from './HelloRetailQuery.gql';
import { useAddwish } from '@jetshop/flight-addwish';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import styled from 'react-emotion';
import { ProductGrid } from './CategoryPage/ProductGrid';
import { useLocation } from 'react-router-dom';
import t from '@jetshop/intl';

const RecommendationRowWrapper = styled('div')`
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0rem;
  ${({ theme }) => theme.below.md} {
    margin-top: 0rem;
  }

  &:empty {
    margin-top: 0rem;
  }
  > div &:empty {
    margin-top: 0rem;
  }
`;

const RecommendationWrapper = styled('div')`
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;

  ${({ theme }) => theme.below.md} {
    padding-top: 1rem;
    padding-bottom: 1rem;

    > div h2 {
      margin-top: 1rem;
    }
  }

  .product-grid {
    margin: 0;
    // justify-content: center;
  }
`;

const TitleWrapper = styled('h2')`
  text-transform: uppercase;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 25px;
  font-family: 'futura-pt', sans-serif;
  font-weight: 700;
`;
const ProductGridWrapper = styled(ProductGrid)`
  width: 100%;

  &.slider {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }

    > .product-card {
      flex: none;
      width: 20%;
      ${({ theme }) => theme.below.lg} {
        width: 25%;
      }
      ${({ theme }) => theme.below.md} {
        width: 33.333%;
      }
      ${({ theme }) => theme.below.sm} {
        width: 50%;
      }
    }
  }
`;

const HelloRetailRecommendationRow = ({ title, products, slider = false }) => {
  return (
    <RecommendationWrapper className={'hello-retail-recommendations'}>
      <div>
        <TitleWrapper>{t(title)}</TitleWrapper>
      </div>

      <ProductGridWrapper
        products={products}
        loading={false}
        listname={title}
        className={'ProductGridWrapper' + (slider ? ' slider' : '')}
        sliderMobile={true}
        slider={slider}
      ></ProductGridWrapper>
    </RecommendationWrapper>
  );
};

/* Example of recommendationList: 
- list of objects containing a dictionary, 
  also possible to add "brand" to dictionary)
  slider (bool) set true if you always wants a slider instead of list
  cart will be passed as parameter in cartflyout
  parents will be passed as paramerter for categories
recommendationsList={[
  {
    title: 'Liknande produkter',
    recommendationId: 'k5f6c8fcb0001aa7397c150e8'
    slider: false,
  },
  {
    title: 'Andra köpte även',
    recommendationId: 'k5f6c8fcb0001aa7397c150a6'
    slider: false,
  }
]} */

export const HelloRetailRecommendations = ({ recommendationsList = [] }) => {
  const { selectedChannel } = useContext(ChannelContext);
  let location = useLocation();
  let path = location.pathname;
  let urlPath = selectedChannel.url ? selectedChannel.url + path : null;
  let boxes = {};

  recommendationsList.forEach((recommendation) => {
    let box = {};
    if (urlPath) {
      box.url = urlPath;
    }
    if (recommendation?.cart?.items) {
      box.urls = recommendation?.cart?.items?.map(
        (item) => selectedChannel.url + item.product.primaryRoute.path
      );
    }
    if (recommendation?.cart?.productTotal?.incVat) {
      box.price = recommendation?.cart?.productTotal?.incVat;
    }
    if (recommendation?.cart?.items) {
      let cartItemsHirachies = recommendation?.cart?.items.map((item) => {
        let array = [];
        for (let i = 0; i < item.product.primaryRoute.parents.length; i++) {
          const child = item.product.primaryRoute.parents[i];
          array.push(child.object.breadcrumbText);
        }
        return array.reverse();
      });
      box.hierarchies = cartItemsHirachies;
    } else if (recommendation.parents) {
      let parentHirachies = recommendation.parents.map((parent) => {
        return parent.object?.breadcrumbText;
      });
      box.hierarchies = [parentHirachies.reverse()];
    }

    if (recommendation.brand) {
      box.brand = recommendation.brand;
    }
    let recId = [recommendation.recommendationId];
    boxes[recId] = box;
  });

  const { addWishResponseBoxItems } = useAddwish({
    boxes: boxes,
    pageLoaded: !loading,
  });

  let totalProductNumbers = [];
  let recommendationRows = [];
  let items = [];
  recommendationsList.forEach((recommendation) => {
    let recommendationRow = {
      title: recommendation.title ?? '',
      productNumbers: [],
      slider: recommendation.slider ?? false,
    };

    items = recommendationsList.flatMap((list) =>
      addWishResponseBoxItems[list.recommendationId]?.items.map((item) => item)
    );

    recommendationRow['productNumbers'] = addWishResponseBoxItems[
      recommendation.recommendationId
    ]?.items.map((item) => item.productNumber);
    recommendationRows.push(recommendationRow);
    totalProductNumbers = totalProductNumbers.concat(
      recommendationRow['productNumbers']
    );
  });
  const helloRetailProductsQueryResult = useQuery(helloRetailQuery, {
    variables: {
      articleNumbers: totalProductNumbers,
    },
    skip: totalProductNumbers.length === 0,
    errorPolicy: 'ignore',
  });

  const allProducts =
    helloRetailProductsQueryResult?.data?.products.map((product) => ({
      ...product,
      addwishData: items.find((item) => {
        // console.log('item?.productNumber', item?.productNumber);
        // console.log('product?.articleNumber', product?.articleNumber);
        // console.log('item', item);
        // console.log('product', product);
        return item?.productNumber === product?.articleNumber;
      }),
    })) || [];
  // console.log('allProducts', allProducts);

  const loading = () => {
    recommendationsList.forEach((recommendation) => {
      if (
        addWishResponseBoxItems[recommendation.recommendationId].loading ===
        'LOADING'
      ) {
        return true;
      }
    });

    if (helloRetailProductsQueryResult.loading) {
      return true;
    }

    return false;
  };

  const rowData = recommendationRows.map((row) => {
    return {
      title: row.title,
      slider: row.slider ?? false,
      products: allProducts.filter((product) => {
        if (row.productNumbers.includes(product.articleNumber)) {
          return product;
        }
      }),
    };
  });

  return (
    <RecommendationRowWrapper>
      {rowData.map((row) =>
        row.products.length > 0 ? (
          <HelloRetailRecommendationRow
            {...row}
            key={row.title + row.products[0].articleNumber}
          />
        ) : null
      )}
    </RecommendationRowWrapper>
  );
};

export default HelloRetailRecommendations;
